// App.tsx
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import StartJoinSession from "./StartJoinSession";
import Gamesession from "./Gamesession";
import CreatorDashboard from "./CreatorDashboard";
import Auth from "./Auth";
import JoinGameWithSessionId from "./Join";
import Donations from "./components/donations";
import { ContentContainer, MainContainer } from "./commonUI";
import DynamicRoute from "./DynamicRoute";

function App() {
  let serverUrl: string;

  if (process.env.NODE_ENV === "development") {
    serverUrl = "http://localhost:4001";
  } else {
    serverUrl = "https://bam.baby";
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<StartJoinSession serverUrl={serverUrl} />} />
        <Route path="/auth" element={<Auth serverUrl={serverUrl} />} />
        <Route
          path="/donate"
          element={
            <MainContainer>
              <ContentContainer>
                <Donations />
              </ContentContainer>
            </MainContainer>
          }
        />
        <Route
          path="/join"
          element={<JoinGameWithSessionId serverUrl={serverUrl} />}
        />
        <Route
          path="/creatorDashboard"
          element={<CreatorDashboard serverUrl={serverUrl} />}
        />
        <Route
          path="/host"
          element={<Gamesession userRole="host" serverUrl={serverUrl} />}
        />
        <Route
          path="/player"
          element={<Gamesession userRole="player" serverUrl={serverUrl} />}
        />
        <Route
          path="/:shortCode"
          element={<DynamicRoute serverUrl={serverUrl} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
