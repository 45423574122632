import React, { Component } from "react";
import styled, { css } from "styled-components";
import { blue, colorWhite, dark, error, mintGreen, warning } from "./commonUI";

interface Props {
  type: "primary" | "secondary" | "warning" | "glass" | "borderOnly" | "error";
  label: string | JSX.Element;
  onClick?: () => void;
  width?: string | "fullWidth";
  size?: "large" | "medium" | "small" | "verySmall" | "veryVerySmall";
  noShadow?: boolean;
}

interface State {
  isClicked: boolean;
}

export class Button extends Component<Props, State> {
  state = {
    isClicked: false,
  };

  render() {
    return (
      <ButtonContainer
        width={this.props.width}
        onPointerDown={() => {
          this.setState({ isClicked: true });
        }}
        onPointerUp={() => {
          this.setState({ isClicked: false });
          if (this.props.onClick) {
            this.props.onClick();
          }
        }}
        isClicked={this.state.isClicked}
        type={this.props.type}
        size={this.props.size}
        noShadow={this.props.noShadow}
      >
        {this.props.label}
      </ButtonContainer>
    );
  }
}
const ButtonContainer = styled.div<{
  type: "primary" | "secondary" | "warning" | "glass" | "borderOnly" | "error";
  width?: string | "fullWidth";
  size?: "large" | "medium" | "small" | "verySmall" | "veryVerySmall";
  noShadow?: boolean;
  isClicked: boolean;
}>`
  padding: 2vw 5vw;
  box-sizing: border-box;
  border: 2px solid ${dark};
  box-shadow: ${(props) => !props.noShadow && "4px 4px 0px rgba(0, 0, 0, 1)"};
  font-family: "Work Sans", sans-serif;
  min-width: 45vw;
  font-size: 3.6vw;
  width: ${(props) => props.width && props.width};
  text-align: center;
  z-index: 1;
  white-space: nowrap;
  color: ${dark};

  ${(props) =>
    props.width === "fullWidth" &&
    css`
      width: 100%;
    `}
  ${(props) =>
    props.type === "primary" &&
    css`
      background: ${mintGreen};
    `}
  ${(props) =>
    props.type === "secondary" &&
    css`
      background: ${blue};
    `}
  ${(props) =>
    props.type === "warning" &&
    css`
      background: ${warning};
    `}
  ${(props) =>
    props.type === "glass" &&
    css`
      color: ${dark};
    `}
  ${(props) =>
    props.type === "borderOnly" &&
    css`
      background-color: ${colorWhite};
      color: ${dark};
    `}
  ${(props) =>
    props.type === "error" &&
    css`
      background-color: ${error};
      color: ${dark};
    `}
  ${(props) =>
    props.size === "large" &&
    css`
      font-size: 3.6vw;
      padding: 2vw 5vw;
      min-width: 40vw;
      border-radius: 1vw;
    `}
  ${(props) =>
    props.size === "medium" &&
    css`
      font-size: 2.5vw;
      padding: 1.2vw 2.5vw;
      min-width: 15vw;
      border-radius: 1vw;
    `}
  ${(props) =>
    props.size === "small" &&
    css`
      font-size: 2vw;
      padding: 1vw 2vw;
      min-width: 10vw;
      border-radius: 1vw;
    `}
  ${(props) =>
    props.size === "verySmall" &&
    css`
      font-size: 1.8;
      padding: 0.8vw 1.5;
      min-width: 8vw;
      border-radius: 1vw;
    `}
  ${(props) =>
    props.size === "veryVerySmall" &&
    css`
      font-size: 1.3vw;
      padding: 0.2vw 1vw;
      border-radius: 1vw;
    `}

    // Media queries for responsiveness
  @media (min-width: 1201px) {
    // For tablets and desktops
    font-size: ${(props) => {
      if (props.size === "large") return "1.5vw";
      if (props.size === "medium") return "1.25vw";
      if (props.size === "veryVerySmall") return "1.3vw";
      return "1vw";
    }};
    padding: ${(props) => {
      if (props.size === "large") return "1.5vw 3vw";
      if (props.size === "medium") return "1vw 2vw";
      if (props.size === "veryVerySmall") return "0.5vw 1vw;";
      return "0.75vw 1.5vw";
    }};
    min-width: ${(props) => {
      if (props.size === "large") return "15vw";
      if (props.size === "medium") return "12vw";
      return "10vw";
    }};
  }

  @media (max-width: 1200px) {
    // For mobile devices
    font-size: ${(props) => {
      if (props.size === "large") return "4.5vw";
      if (props.size === "medium") return "3.5vw";
      if (props.size === "veryVerySmall") return "2.8vw";
      return "3vw";
    }};
    padding: ${(props) => {
      if (props.size === "large") return "3vw 6vw";
      if (props.size === "medium") return "2.5vw 5vw";
      if (props.size === "small") return "2.5vw 5vw";
      if (props.size === "veryVerySmall") return "1vw 1vw;";
      return "2vw 4vw";
    }};
    min-width: ${(props) => {
      if (props.size === "large") return "30vw";
      if (props.size === "medium") return "25vw";
      return "20vw";
    }};
  }
  ${(props) =>
    props.isClicked &&
    css`
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 1);
      transform: translate(
        4px,
        4px
      ); // Move the button 4px to the right and 4px down
    `}
  user-select: none;
`;
