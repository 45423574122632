import React, { Component, createRef } from "react";

interface State {
  positions: { x: number; y: number }[];
  velocities: { vx: number; vy: number }[];
  heights: { height: string }[];
  rotations: number[];
  rotationSpeeds: number[]; // Add this line
  parentWidth: number; // Add this line
  parentHeight: number; // Add this line
}

interface Props {
  isFullScreen?: boolean;
}

const windowHeight = window.innerHeight;

class BouncingImages extends Component<Props, State> {
  containerRef = createRef<HTMLDivElement>();
  state = {
    positions: [] as { x: number; y: number }[],
    velocities: [] as { vx: number; vy: number }[],
    heights: [] as { height: string }[],
    rotations: [] as number[],
    rotationSpeeds: [],
    parentWidth: 0, // Add this line
    parentHeight: 0, // Add this line
  };
  supaBaseURL = "https://cjzbftkyxnbpyozbgprl.supabase.co";
  images = [
    `${this.supaBaseURL}/storage/v1/object/public/assets/starImage.png`,
    `${this.supaBaseURL}/storage/v1/object/public/assets/stickImage.png`,
    `${this.supaBaseURL}/storage/v1/object/public/assets/smallStarImage.png`,
    `${this.supaBaseURL}/storage/v1/object/public/assets/sunImage.png`,
    `${this.supaBaseURL}/storage/v1/object/public/assets/circleImage.png`,
  ];
  intervalId: NodeJS.Timeout | null = null;

  componentDidMount() {
    this.updateParentDimensions(); // Add this line
    window.addEventListener("resize", this.updateParentDimensions); // Add this line to handle window resize
    const initialPositions = this.images.map(() => {
      const imageWidth = 50; // Assuming a default width, you can adjust this as needed
      const x = Math.random() * (this.state.parentWidth - imageWidth); // Update this line
      const y = Math.random() * (this.state.parentHeight - imageWidth); // Update this line
      return { x, y };
    });
    const initialRotations = this.images.map(() => Math.random() * 360);
    const initialRotationSpeeds = this.images.map(() => Math.random() * 1); // Initialize rotation speeds

    const initialVelocities = this.images.map(() => {
      let vx = (Math.random() - 0.5) * 0.0000001; // Further reduced range
      let vy = (Math.random() - 0.5) * 0.0000001; // Further reduced range
      // Ensure velocities are not too close to zero
      if (Math.abs(vx) < 0.00000001) vx += (Math.random() - 0.5) * 0.00002; // Further reduced range
      if (Math.abs(vy) < 0.00000001) vy += (Math.random() - 0.5) * 0.00002; // Further reduced range
      return { vx, vy };
    });

    const initialHeights = this.images.map(() => ({
      height: `${20 + Math.random() * 5}vw`,
    }));
    this.setState({
      positions: initialPositions,
      velocities: initialVelocities,
      heights: initialHeights,
      rotations: initialRotations,
      rotationSpeeds: initialRotationSpeeds,
    });

    this.intervalId = setInterval(this.updatePositions, 16);
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    window.removeEventListener("resize", this.updateParentDimensions); // Clean up event listener
  }

  updateParentDimensions = () => {
    if (this.containerRef.current) {
      const parentElement = this.containerRef.current.parentElement;
      if (parentElement) {
        this.setState(
          {
            parentWidth: parentElement.clientWidth,
            parentHeight: parentElement.clientHeight,
          },
          this.initializePositions // Callback to initialize positions after state update
        );
      }
    }
  };

  initializePositions = () => {
    const initialPositions = this.images.map(() => {
      const imageWidth = 50; // Assuming a default width, you can adjust this as needed
      const x = Math.random() * (this.state.parentWidth - imageWidth);
      const y = Math.random() * (this.state.parentHeight - imageWidth);
      return { x, y };
    });
    const initialRotations = this.images.map(() => Math.random() * 360);
    const initialRotationSpeeds = this.images.map(() => Math.random() * 1);

    const initialVelocities = this.images.map(() => {
      let vx = (Math.random() - 1) * 0.5;
      let vy = (Math.random() - 1) * 0.5;
      if (Math.abs(vx) < 0.1) vx += (Math.random() - 0.5) * 0.002;
      if (Math.abs(vy) < 0.1) vy += (Math.random() - 0.5) * 0.002;
      return { vx, vy };
    });
    const initialHeights = this.images.map(() => ({
      height: `${20 + Math.random() * 5}vw`,
    }));
    this.setState({
      positions: initialPositions,
      velocities: initialVelocities,
      heights: initialHeights,
      rotations: initialRotations,
      rotationSpeeds: initialRotationSpeeds,
    });

    if (!this.intervalId) {
      this.intervalId = setInterval(this.updatePositions, 16);
    }
  };

  updatePositions = () => {
    this.setState((prevState) => ({
      positions: prevState.positions.map((pos, index) => {
        let { x, y } = pos;
        let { vx, vy } = prevState.velocities[index];
        const imageElement = document.querySelectorAll("img")[index];
        const imageWidth = imageElement ? imageElement.clientWidth : 50; // Get actual image width

        x += vx;
        y += vy;

        if (x <= 0 || x + imageWidth >= prevState.parentWidth) vx = -vx; // Update this line
        if (y <= 0 || y + imageWidth >= prevState.parentHeight) vy = -vy; // Update this line

        prevState.velocities[index] = { vx, vy };

        return { x, y };
      }),
      rotations: prevState.rotations.map(
        (rotation, index) => (rotation + prevState.rotationSpeeds[index]) % 360 // Use rotation speeds
      ),
    }));
  };

  render() {
    return (
      <div
        ref={this.containerRef}
        style={{
          width: "100vw",
          height: windowHeight,
          zIndex: 0,
          overflow: "hidden",
          opacity: 0.3,
          position: this.props.isFullScreen ? "absolute" : "relative",
        }}
      >
        {this.state.positions.map((pos, index) => (
          <img
            key={index}
            src={this.images[index]}
            alt={`img-${index}`}
            style={{
              position: "absolute",
              left: pos.x,
              top: pos.y,
              width: this.state.heights[index].height,
              height: this.state.heights[index].height,
              transform: `rotate(${this.state.rotations[index]}deg)`,
            }}
          />
        ))}
      </div>
    );
  }
}

export default BouncingImages;
