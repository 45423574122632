import styled, { css } from "styled-components";
import { ColorThemeKeys, THEME, ThemeColors } from "./theme";

const windowHeight = window.innerHeight;

export const magenta = `#FD9FDD`;
export const dark = `#000000`;
export const colorWhite = `#fff`;
export const mintGreen = `#00D68F`;
export const blue = `#0095FF`;
export const warning = `#FFAA00`;
export const error = `#FF3D71`;
export const greyScale = `#2E3A59`;

const colorMap = {
  magenta,
  dark,
  mintGreen,
  blue,
  warning,
  error,
};

export const MainContainer = styled.div<{
  color?: "magenta" | "dark" | "mintGreen" | "blue" | "warning" | "error";
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: ${(props) => (props.color && colorMap[props.color]) ?? magenta};

  height: ${windowHeight}px;
  width: 100%;
  overflow: auto;
  overflow-y: auto;

  @media (max-width: 1200px) {
    /* Adjustments for screens smaller than 1200px */
  }

  @media (min-width: 1201px) {
  }
`;
export const ContentContainer = styled.div<{
  orientation?: "centerCenter";
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    /* Adjustments for screens smaller than 1200px */
  }

  @media (min-width: 1201px) {
    max-width: 700px;
  }

  ${(props) =>
    props.orientation === "centerCenter" &&
    `
    align-items: center;
    justify-content: center;

    > div {
      margin-top: -15vh;
    }
  `}
`;

export const TitleBox = styled.div<{
  margin?: string;
  gridGap?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5vh;
  z-index: 1;
  min-width: fit-content;
  width: 100%;
  line-height: 1.6;

  ${(props) =>
    props.margin &&
    css<any>`
      margin: ${(props) => props.margin};
    `}
  ${(props) =>
    props.gridGap &&
    css<any>`
      grid-gap: ${(props) => props.gridGap};
    `}

  @media (max-width: 1200px) {
    grid-gap: 0.5vh;
  }

  @media (min-width: 1201px) {
    grid-gap: 1vh;
  }
`;

export const Title = styled.div<{
  size: number;
  horizontalAlign?: "center" | "left" | "right";
  bold?: boolean;
  color?: ColorThemeKeys;
}>`
  text-align: left;
  /* background: linear-gradient(266.77deg, #6ae394 -18.13%, #fafafa 104.34%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: ${(props) =>
    props.color ? THEME().colors[props.color] : THEME().colors["dark"]};
  font-size: ${(props) => props.size * 3 ?? 3}vw;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  background-clip: text;
  z-index: 1;
  width: 100%;
  text-align: center;
  justify-content: center;

  @media (max-width: 1200px) {
    font-size: ${(props) =>
      props.size * 3 ?? 2}vw; /* Smaller font size for smaller screens */
  }

  @media (min-width: 1201px) {
    font-size: ${(props) =>
      props.size * 1.5 ?? 2}vw; /* Larger font size for larger screens */
  }

  ${(props) =>
    props.horizontalAlign === "center" &&
    `
    text-align: center;
    justify-content: center;
  `}

  ${(props) =>
    props.horizontalAlign === "right" &&
    `
    text-align: right;
    justify-content: flex-end;
  `}

  ${(props) =>
    props.horizontalAlign === "left" &&
    `
    text-align: left;
    justify-content: flex-start;
  `}
`;

export const SubTitle = styled.div<{
  width?: string;
  horizontalAlign?: "center" | "left" | "right";
  size?: number;
  color?: ThemeColors;
}>`
  color: ${(props) =>
    props.color ? THEME().colors[props.color] : THEME().colors.colorWhite};
  font-size: 3.5vw;
  text-align: center;
  width: ${(props) => props.width ?? "100%"};
  z-index: 1;

  @media (max-width: 1200px) {
    font-size: ${(props) => (props.size ? props.size * 2.5 : 4.5)}vw;
    line-height: ${(props) => (props.size ? props.size * 3.5 : 7.5)}vw;
  }

  @media (min-width: 1201px) {
    font-size: ${(props) => (props.size ? props.size * 1.2 : 1.2)}vw;
    line-height: ${(props) => (props.size ? props.size * 2 : 7.5)}vw;
  }

  ${(props) =>
    props.horizontalAlign === "center" &&
    `
    text-align: center;
    justify-content: center;
  `}

  ${(props) =>
    props.horizontalAlign === "right" &&
    `
    text-align: right;
    justify-content: flex-end;
  `}

  ${(props) =>
    props.horizontalAlign === "left" &&
    `
    text-align: left;
    justify-content: flex-start;
  `}
`;

export const TextField = styled.input<{
  width?: string;
}>`
  color: #f1f1f1;
  font-size: 3.5vw;
  text-align: left;
  width: ${(props) => props.width ?? "100%"};
  background: #313239;
  border: 1px solid #474747;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    border-radius: 1.2vw;
    font-size: 4vw;
    padding: 3.5vw;
    height: 6vh;
  }

  @media (min-width: 1201px) {
    border-radius: 0.5vw;
    font-size: 1.2vw;
    padding: 1.5vw;
    height: 3vh;
  }
`;

export const TextArea = styled.textarea<{
  width?: string;
  height?: string;
}>`
  color: #f1f1f1;
  font-size: 3.5vw;
  text-align: left;
  width: ${(props) => props.width ?? "100%"};
  height: ${(props) => props.height ?? "10vh"};
  background: #313239;
  border: 1px solid #474747;
  border-radius: 6px;
  padding: 2vw;
  box-sizing: border-box;
  font-family: "Arial";
  resize: vertical;

  @media (max-width: 1200px) {
    border-radius: 1.2vw;
    font-size: 3.5vw;
    padding: 3.5vw;
    height: 20vh;
  }

  @media (min-width: 1201px) {
    font-size: 1.2vw;
    padding: 1.5vw;
    height: 3vh;
    height: 12vh;
  }
`;

export const GameCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 10px;
  box-sizing: border-box;
  grid-gap: 10px;
  background: ${THEME().colors.lightGrey};
  width: 100%;

  @media (max-width: 1200px) {
    padding: 4vw;
    grid-gap: 1vh;
  }

  @media (min-width: 1201px) {
    padding: 2vw;
    grid-gap: 2.5vh;
  }
`;

export const GameTitle = styled.div`
  color: ${THEME().colors.dark};
  font-size: 18px;

  @media (max-width: 1200px) {
    font-size: 4.5vw;
  }

  @media (min-width: 1201px) {
    font-size: 2vw;
  }
`;

export const GameDescription = styled.div`
  color: ${THEME().colors.greyScale};

  @media (max-width: 1200px) {
    line-height: 5vw;
    font-size: 3.5vw;
    margin-bottom: 0.5vh;
  }

  @media (min-width: 1201px) {
    font-size: 1.5vw;
    line-height: 2.5vw;
    margin-bottom: 1.5vh;
  }
`;

export const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    margin-top: 2vh;
    grid-gap: 2vh;
  }

  @media (min-width: 1201px) {
    margin-top: 2vh;
    grid-gap: 2vh;
  }
`;
