import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";
import { CreatorData, GameData } from "./database.types";
import {
  ButtonColumn,
  ContentContainer,
  GameCard,
  GameDescription,
  GameTitle,
  MainContainer,
  TextArea,
  TextField,
  Title,
  TitleBox,
} from "./commonUI";
import { v4 as uuidv4 } from "uuid";
import { Button } from "./Button";
import axios from "axios";
import { LoadingScreen } from "./LoadingScreen";
import { LoadingIndicator } from "./LoadingIndicator";

interface Props {
  serverUrl: string;
}

interface Group {
  id: number;
  name: string;
  maxPlayers: number | null;
  audioFileUrl: string;
}

let assetsServerUrl: string = "https://bamory.fra1.digitaloceanspaces.com";

// Define an interface for the component's state
interface GameState {
  creatorId?: CreatorData["creatorId"];
  gameId: string | null;
  name: string | null;
  description: string | null;
  privacy: "public" | "private";
  playerRecommendations: "single" | "couple" | "friends" | "strangers" | null;
  gameGroups: Group[];
}

const CreateGame: React.FC<Props> = ({ serverUrl }) => {
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const gameId = queryParams.get("gameId");
  const creatorIdFromQuery = queryParams.get("creatorId") ?? "";

  const [gameEditState, setGameEditState] = useState<GameState>({
    creatorId: creatorIdFromQuery ?? null,
    gameId: gameId ?? null,
    name: null,
    description: null,
    privacy: "private",
    playerRecommendations: "strangers",
    gameGroups: [],
  });
  const [uiState, setUIState] = useState("createGame");
  const [uploadStates, setUploadStates] = useState<{
    [key: number]: "pristine" | "running" | "success" | "error";
  }>({});

  const [games, setGames] = useState<GameData[] | null>(null);
  const [saveState, setSaveState] = useState<
    "pristine" | "hasUpdates" | "running" | "success" | "error"
  >("pristine");
  const [confirmDeleteGroup, setConfirmDeleteGroup] = useState<number | null>(
    null
  );
  // const [loadingState, setLoadingState] = useState("pristine");

  useEffect(() => {
    if (!gameId) {
      const newGameId = uuidv4();
      setGameEditState((prevState) => ({
        ...prevState,
        gameId: newGameId,
      }));
    } else {
      // Optionally, fetch game data here if gameId exists
    }
    if (!games) {
      const getAllGames = async () => {
        const response = await fetch(
          `${serverUrl}/get-all-games-by-user?creatorId=${gameEditState.creatorId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setGames(data.games);
      };
      getAllGames();
    }
  }, [gameId, games, gameEditState.creatorId, serverUrl]); // Added dependencies here

  const handleAddGroup = () => {
    const newGroup: Group = {
      id: gameEditState.gameGroups.length + 1,
      name: "",
      maxPlayers: null,
      audioFileUrl: "",
    };
    setGameEditState((prevState) => ({
      ...prevState,
      gameGroups: [...prevState.gameGroups, newGroup],
    }));
    setUploadStates((prevState) => ({
      ...prevState,
      [newGroup.id]: "pristine", // Initialize upload state for new group
    }));
  };

  const onFileUpload = async (event: any, groupId: number) => {
    const file = event.target.files[0];
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
      formData.append("gameId", gameEditState.gameId ?? "");
      setUploadStates((prevState) => ({ ...prevState, [groupId]: "running" })); // Set uploading state
    } else {
      console.error("No file selected");
    }

    try {
      const response = await axios.post(serverUrl + "/upload-file", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const fileUrl = response.data.fileUrl;

      setGameEditState((prevState) => ({
        ...prevState,
        gameGroups: prevState.gameGroups.map((group) =>
          group.id === groupId ? { ...group, audioFileUrl: fileUrl } : group
        ),
      }));
      setUploadStates((prevState) => ({ ...prevState, [groupId]: "success" })); // Set success state
    } catch (error) {
      console.log("Error:", error);
      setUploadStates((prevState) => ({ ...prevState, [groupId]: "error" })); // Set success state
    }
  };

  const handleGroupChange = (
    id: number,
    field: keyof Group,
    value: string | number | null
  ) => {
    setSaveState("hasUpdates");
    setGameEditState((prevState) => ({
      ...prevState,
      gameGroups: prevState.gameGroups.map((group) =>
        group.id === id ? { ...group, [field]: value } : group
      ),
    }));
  };

  const handleSaveGame = async () => {
    const currentGameEditState = gameEditState;
    setSaveState("running");

    await fetch(`${serverUrl}/edit-game-data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ gameData: currentGameEditState }),
    });
    setSaveState("success");
  };
  const handleLaunchGame = async (gameId: string) => {
    const response = await fetch(
      `${serverUrl}/create-session-from-game-as-host?gameId=${gameId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    const sessionId = data.sessionId;
    navigate(`/host?sessionId=${sessionId}`);
  };
  const handleEditGame = async (gameId: string) => {
    const response = await fetch(
      `${serverUrl}/get-game-data?gameId=${gameId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    const gameData = data.gameData;
    const gameGroups = JSON.parse(data.gameData.gameGroups);
    gameData.gameGroups = gameGroups;
    setGameEditState(gameData);
    setUIState("createGame");
    setSaveState("hasUpdates");
  };

  // Function to handle changes in the text field
  const handleNameChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setSaveState("hasUpdates");
    setGameEditState((prevState) => ({
      ...prevState,
      name: event.target.value,
    }));
  };
  const handleChangeGameDescription = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setSaveState("hasUpdates");
    setGameEditState((prevState) => ({
      ...prevState,
      description: event.target.value,
    }));
  };

  const modeSwitcher = (
    size: "small" | "medium" | "large" = "medium",
    options: string[] = ["Create Game", "My Games"],
    onClick: (() => void)[] = [
      () => setUIState("createGame"),
      () => setUIState("myGames"),
    ],
    leftActive: boolean = uiState === "createGame",
    rightActive: boolean = uiState === "myGames"
  ) => {
    return (
      <SwitcherContainer size={size}>
        <SwitcherButton active={leftActive} onClick={onClick[0]} size={size}>
          {options[0]}
        </SwitcherButton>
        <SwitcherButton size={size} active={rightActive} onClick={onClick[1]}>
          {options[1]}
        </SwitcherButton>
      </SwitcherContainer>
    );
  };

  if (uiState === "myGames") {
    return (
      <MainContainer>
        <ContentContainer>
          <TopArea>{modeSwitcher()}</TopArea>
          <BottomSection>
            <AvailableGamesContainer>
              {games != null &&
                games?.map((game: GameData) => (
                  <GameCard key={game.gameId}>
                    <GameTitle>{game.name}</GameTitle>
                    <GameDescription>{game.description}</GameDescription>
                    <ButtonColumn>
                      <Button
                        width="fullWidth"
                        onClick={() => handleLaunchGame(game.gameId)}
                        type="secondary"
                        size="medium"
                        label="Host this Game"
                      />
                      <Button
                        width="fullWidth"
                        onClick={() => handleEditGame(game.gameId)}
                        type="secondary"
                        size="medium"
                        label="Edit Game"
                      />
                    </ButtonColumn>
                  </GameCard>
                ))}
            </AvailableGamesContainer>
          </BottomSection>
        </ContentContainer>
      </MainContainer>
    );
  }
  if (uiState === "createGame") {
    return (
      <MainContainer>
        <ContentContainer>
          <TopArea>{modeSwitcher()}</TopArea>
          <BottomSection>
            {/* Section For Title and Description */}
            <FormSection>
              <FormTitles>Name of Game</FormTitles>
              <TextField
                value={gameEditState.name ?? ""}
                onChange={handleNameChange}
              />
            </FormSection>
            <FormSection>
              <FormTitles>Public Description</FormTitles>
              <TextArea
                value={gameEditState.description ?? ""}
                height={"20vh"}
                onChange={handleChangeGameDescription}
              />
            </FormSection>
            {/* <FormSection>
              <FormTitles>Privacy Settings</FormTitles>
              {modeSwitcher(
                "small",
                ["private", "public"],
                [
                  () => {
                    setGameEditState((prevState) => ({
                      ...prevState,
                      privacy: "private",
                    }));
                    setSaveState("hasUpdates");
                  },
                  () => {
                    setGameEditState((prevState) => ({
                      ...prevState,
                      privacy: "public",
                    }));
                    setSaveState("hasUpdates");
                  },
                ],
                gameEditState.privacy === "private",
                gameEditState.privacy === "public"
              )}
            </FormSection> */}
            {/* Section for Group selection */}
            <FormSection>
              <TitleBox margin="2vw 0 0.5vw 0">
                <Title size={1.7}>Groups</Title>
              </TitleBox>
              <AvailableGamesContainer>
                {gameEditState.gameGroups.map((group) => (
                  <GameCard key={group.id}>
                    <FormSection>
                      <FormTitles>Group Name</FormTitles>
                      <TextField
                        value={group.name}
                        onChange={(e) =>
                          handleGroupChange(group.id, "name", e.target.value)
                        }
                        placeholder="Group Name"
                        width="100%"
                      />
                    </FormSection>
                    <FormSection>
                      <FormTitles>Maximum Players for Group</FormTitles>
                      <TextField
                        type="number"
                        value={group.maxPlayers ?? ""}
                        onChange={(e) =>
                          handleGroupChange(
                            group.id,
                            "maxPlayers",
                            e.target.value === ""
                              ? null
                              : Math.max(0, Number(e.target.value))
                          )
                        }
                        placeholder="Max Players"
                      />
                    </FormSection>
                    <FormSection>
                      <FormTitles>Audio File URL for Group</FormTitles>
                      <FileUploadLabel>
                        {uploadStates[group.id] === "running" ? (
                          <UploadContainer>
                            <LoadingIndicator heightAndWidth={"2.4vh"} />{" "}
                            Uploading - Can take a sec
                          </UploadContainer>
                        ) : uploadStates[group.id] === "success" ? (
                          "Upload Successful"
                        ) : (
                          "Upload Audio File"
                        )}
                        <input
                          type="file"
                          style={{ display: "none" }}
                          onChange={async (event) =>
                            await onFileUpload(event, group.id)
                          }
                        />
                      </FileUploadLabel>
                      <TextField
                        value={group.audioFileUrl}
                        onChange={(e) =>
                          handleGroupChange(
                            group.id,
                            "audioFileUrl",
                            e.target.value
                          )
                        }
                        placeholder="..or add audio File URL"
                      />
                    </FormSection>
                    <Button
                      label={
                        confirmDeleteGroup === group.id
                          ? "Confirm Delete"
                          : "Delete Group"
                      }
                      type="warning"
                      size="medium"
                      width="fullWidth"
                      onClick={() => {
                        if (confirmDeleteGroup === group.id) {
                          setGameEditState((prevState) => ({
                            ...prevState,
                            gameGroups: prevState.gameGroups.filter(
                              (g) => g.id !== group.id
                            ),
                          }));
                          setConfirmDeleteGroup(null); // Reset the confirmation state
                          setSaveState("hasUpdates");
                        } else {
                          setConfirmDeleteGroup(group.id); // Set this group as being confirmed
                        }
                      }}
                    />
                  </GameCard>
                ))}
              </AvailableGamesContainer>
              <Button
                label="Add New Group"
                size="large"
                type="secondary"
                width="fullWidth"
                onClick={handleAddGroup}
              />
            </FormSection>

            {/* Section for ideal player */}
            {/* Sectino for Privacy */}
            {gameEditState.gameGroups.length > 0 &&
              saveState === "hasUpdates" && (
                <Button
                  label="Save Game"
                  type="primary"
                  size="large"
                  width="fullWidth"
                  onClick={handleSaveGame}
                />
              )}
            {gameEditState.gameGroups.length > 0 && saveState === "running" && (
              <Button
                label="Saving Game..."
                type="primary"
                size="large"
                width="fullWidth"
              />
            )}
            {gameEditState.gameId &&
              gameEditState.gameGroups.length > 0 &&
              (saveState === "success" || saveState === "pristine") && (
                <Button
                  label="Launch Game"
                  type="primary"
                  size="large"
                  width="fullWidth"
                  onClick={() => {
                    if (gameEditState.gameId) {
                      handleLaunchGame(gameEditState?.gameId);
                    }
                  }}
                />
              )}
          </BottomSection>
        </ContentContainer>
      </MainContainer>
    );
  }
  return null;
};

export default CreateGame;

const FileUploadLabel = styled.label`
  background: #5d5f6d;
  color: white;
  border: none;
  border-radius: 10vh;
  padding: 1.5vh;
  width: 100%;
  box-sizing: border-box;
  margin-top: 1vh;
  display: inline-block;
  cursor: pointer;
  text-align: center;

  @media (max-width: 1200px) {
    padding: 1.5vh;
    font-size: 4vw;
  }

  @media (min-width: 1201px) {
    padding: 1vh;
    font-size: 1vw;
  }
`;

const FormSection = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;

  @media (max-width: 1200px) {
    margin-top: 2.5vh;
    grid-gap: 1.5vh;
  }

  @media (min-width: 1201px) {
    margin-top: 2.5vh;
    grid-gap: 1.5vh;
  }
`;

const FormTitles = styled.div`
  color: #a4a4a4;
  text-align: left;
  width: 100%;

  @media (max-width: 1200px) {
    font-size: 3.5vw;
  }

  @media (min-width: 1201px) {
    font-size: 1.5vw;
  }
`;

const AvailableGamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;

  @media (max-width: 1200px) {
    grid-gap: 1vh;
  }

  @media (min-width: 1201px) {
    grid-gap: 1vh;
  }
`;

const SwitcherContainer = styled.div<{
  size: "small" | "medium" | "large";
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 10px;
  width: 100%;
  border-radius: 10vh;
  padding: 5px;
  box-sizing: border-box;
  background: #313239;

  @media (max-width: 1200px) {
    ${(props) =>
      props.size === "medium" &&
      css`
        padding: 1vh;
      `}
    ${(props) =>
      props.size === "small" &&
      css`
        padding: 1.2vw;
      `}
  }

  @media (min-width: 1201px) {
    ${(props) =>
      props.size === "medium" &&
      css`
        padding: 1vh;
        margin: 0px;
      `}
    ${(props) =>
      props.size === "small" &&
      css`
        padding: 1vh;
        margin: 0px;
      `}
  }
`;
const SwitcherButton = styled.div<{
  active: boolean;
  size: "small" | "medium" | "large";
}>`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  text-align: center;
  width: 50%;
  border-radius: 10vh;
  box-sizing: border-box;
  height: 100%;
  flex: 1;
  ${(props) =>
    props.active &&
    css`
      background: #5d5f6d;
    `}
  @media (max-width: 1200px) {
    ${(props) =>
      props.size === "medium" &&
      css`
        font-size: 4vw;
        padding: 1.5vw;
      `}
    ${(props) =>
      props.size === "small" &&
      css`
        font-size: 3vw;
        padding: 1.5vw;
      `}
  }

  @media (min-width: 1201px) {
    ${(props) =>
      props.size === "medium" &&
      css`
        font-size: 1.5vw;
        padding: 1.5vh;
      `}
    ${(props) =>
      props.size === "small" &&
      css`
        font-size: 1vw;
        padding: 1vh;
        width: 100%;
      `}
  }
`;

const TopArea = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  width: -webkit-fill-available;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    margin: 1vw 3vw 5vw 3vw;
  }
  @media (min-width: 1201px) {
    margin: 1vw;
  }
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  grid-gap: 10px;
  padding-bottom: 15vh;
  border-top: 1px solid #414141;
  box-sizing: border-box;
`;

const UploadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  grid-gap: 1vw;
`;
