import { Component } from "react";
import styled from "styled-components";
import { Button } from "./Button";

interface Props {
  step:
    | "welcome"
    | "setupConnection"
    | "soundCheck"
    | "doNotDisturbMode"
    | "downloadAudio"
    | "audioIntroduction"
    | "runningGame";
  closeComponent: () => void;
}

interface State {}

const fallBackLink = "https://bam.brunopilz.com/index.php?target=awareness";

export class TroubleShooting extends Component<Props, State> {
  renderOnboardingContent = () => {
    const content = TroubleShootingContent[this.props.step];

    return (
      <TroubleShootingContainer>
        <Button
          type={"primary"}
          label={"Go Back"}
          onClick={this.props.closeComponent}
          width={"fullWidth"}
        />
        {content.sections.map((section: any, index: number) => (
          <AnswerBox key={index}>
            <Problem>{section.problem}</Problem>
            <Solution>{section.solution}</Solution>
          </AnswerBox>
        ))}
      </TroubleShootingContainer>
    );
  };

  render() {
    return this.renderOnboardingContent();
  }
}

const TroubleShootingContent = {
  welcome: {
    sections: [
      {
        problem: "",
        solution: "",
      },
    ],
  },
  setupConnection: {
    sections: [
      {
        problem: "The audio won't download, it never seems to progress",
        solution: (
          <span>
            First try to reload the page and try again.
            <br />
            If the problem persists, try in another browser. <br /> If it still
            persists, <a href={fallBackLink}>
              {" "}
              try the fallback option here
            </a>{" "}
            or talk to the hosts.
          </span>
        ),
      },
      {
        problem: "Audio is downloaded but I can't progress to the next step",
        solution: (
          <span>
            First try to reload the page and try again.
            <br />
            If the problem persists, try in another browser. <br /> If it still
            persists, <a href={fallBackLink}>
              {" "}
              try the fallback option here
            </a>{" "}
            or talk to the hosts.
          </span>
        ),
      },
    ],
  },
  soundCheck: {
    sections: [
      {
        problem: "I can't hear the sound",
        solution: (
          <span>
            The obvious first: Are your headphones really connected and audio is
            turned high enough?
            <br />
            If the problem persists, first reload the tab and then try another
            browser. <br /> If it still persists,{" "}
            <a href={fallBackLink}> try the fallback option here</a> or talk to
            the hosts
          </span>
        ),
      },
    ],
  },
  doNotDisturbMode: {
    sections: [],
  },
  audioIntroduction: {
    sections: [
      {
        problem: "",
        solution: "",
      },
    ],
  },
  downloadAudio: {
    sections: [
      {
        problem:
          "In case none of the solutions below work we have fallback option",
        solution: (
          <span>
            First try to reload the page and try again.
            <br />
            If the problem persists, try in another browser. <br /> If it still
            persists, <a href={fallBackLink}>
              {" "}
              try the fallback option here
            </a>{" "}
            or talk to the hosts.
          </span>
        ),
      },
      {
        problem: "The audio won't download, it never seems to progress",
        solution: (
          <span>
            First try to reload the page and try again.
            <br />
            If the problem persists, try in another browser. <br /> If it still
            persists, <a href={fallBackLink}>
              {" "}
              try the fallback option here
            </a>{" "}
            or talk to the hosts.
          </span>
        ),
      },
      {
        problem: "Audio is downloaded but I can't progress to the next step",
        solution: (
          <span>
            First try to reload the page and try again.
            <br />
            If the problem persists, try in another browser. <br /> If it still
            persists, <a href={fallBackLink}>
              {" "}
              try the fallback option here
            </a>{" "}
            or talk to the hosts.
          </span>
        ),
      },
    ],
  },
  runningGame: {
    sections: [
      {
        problem: "I seem to be not in sync with the other players",
        solution: (
          <span>
            Mind that there are different groups different games so you may be
            on a VERY different timeline than the others.
            <br />
            Check the game timer on the laptop that the hosts have with them if
            you are in sync. <br />
            If you are off, use the "Resync Time" button to get yourself back on
            their track
          </span>
        ),
      },
      {
        problem: "My audio keeps turning off",
        solution: (
          <span>
            Do you have the low-energy mode disabled?
            <br />
            If so and you still run into issues, try another browser. <br />
            if your problem persists,{" "}
            <a href={fallBackLink}> try the fallback option here</a> or talk to
            the hosts.
          </span>
        ),
      },
      {
        problem: "I tried the resync button but it didn't seem to work",
        solution: (
          <span>
            Try to reload the page and enter the game again.
            <br /> If that does not work, press the "Reset Game" button to kick
            yourself out of the game and join again freshly
            <br /> If that didn't work talk to the hosts.
          </span>
        ),
      },
    ],
  },
};

const Problem = styled.div`
  font-size: 4vw;
  font-weight: bold;
  color: #cacad1;
  line-height: 1.6;
`;
const Solution = styled.div`
  color: #a9a9b1;
  font-size: 3.5vw;
  line-height: 1.6;
`;
const AnswerBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 5vw 5vw;
  margin-bottom: 10px;
  grid-gap: 1vh;
  box-sizing: border-box;

  a {
    color: #6ae394;
  }
`;

const TroubleShootingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 20px;
  position: absolute;
  z-index: 100;
  top: 0px;
  left: 0px;
  background: #12131b;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
`;
