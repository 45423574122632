import React, { Component } from "react";
import styled, { css } from "styled-components";
import { blue, colorWhite, dark, error, mintGreen, warning } from "./commonUI";
import Icon from "./icon";
import { IconThemeKeys } from "./theme";

interface Props {
  type: "primary" | "secondary" | "warning" | "glass" | "borderOnly" | "error";
  label: string | JSX.Element;
  icon: IconThemeKeys | JSX.Element;
  onClick?: () => void;
  heightAndWidth: string;
  size?: "large" | "medium" | "small" | "verySmall";
  noShadow?: boolean;
}

interface State {
  isClicked: boolean;
}

export class RoundButton extends Component<Props, State> {
  state = {
    isClicked: false,
  };
  render() {
    return (
      <ButtonContainer
        onPointerDown={() => {
          this.setState({ isClicked: true });
        }}
        onPointerUp={() => {
          this.setState({ isClicked: false });
          if (this.props.onClick) {
            this.props.onClick();
          }
        }}
        type={this.props.type}
        size={this.props.size}
        noShadow={this.props.noShadow}
      >
        {typeof this.props.icon === "string" ? (
          <IconBox
            heightAndWidth={this.props.heightAndWidth}
            type={this.props.type}
            noShadow={this.props.noShadow}
            isClicked={this.state.isClicked}
          >
            <Icon heightAndWidth={"40%"} name={this.props.icon} />
          </IconBox>
        ) : (
          this.props.icon
        )}
        {this.props.label && this.props.label}
      </ButtonContainer>
    );
  }
}

const ButtonContainer = styled.div<{
  type: "primary" | "secondary" | "warning" | "glass" | "borderOnly" | "error";
  size?: "large" | "medium" | "small" | "verySmall";
  noShadow?: boolean;
}>`
  border-radius: 100vw;

  box-sizing: border-box;
  height: fit-content;
  width: fit-content;
  font-family: "Work Sans", sans-serif;
  font-size: 3.6vw;
  text-align: center;
  z-index: 1;
  white-space: nowrap;
  box-sizing: border-box;
  color: ${dark};
  flex-direction: column;
  display: flex;
  grid-gap: 2vh;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.size === "large" &&
    css`
      font-size: 3.6vw;
      padding: 2vw 5vw;
      border-radius: 1vw;
    `}
  ${(props) =>
    props.size === "medium" &&
    css`
      font-size: 2.5vw;
      padding: 1.2vw 2.5vw;
      border-radius: 1vw;
    `}
  ${(props) =>
    props.size === "small" &&
    css`
      font-size: 2vw;
      padding: 1vw 2vw;
      border-radius: 1vw;
    `}
  ${(props) =>
    props.size === "verySmall" &&
    css`
      font-size: 1.8;
      padding: 0.8vw 1.5;
      border-radius: 1vw;
    `}

    // Media queries for responsiveness
  @media (min-width: 1201px) {
    // For tablets and desktops
    font-size: ${(props) => {
      if (props.size === "large") return "1.5vw";
      if (props.size === "medium") return "1.25vw";
      return "1vw";
    }};
  }

  @media (max-width: 1200px) {
    // For mobile devices
    font-size: ${(props) => {
      if (props.size === "large") return "4.5vw";
      if (props.size === "medium") return "3.5vw";
      return "3.2vw";
    }};
  }
`;

const IconBox = styled.div<{
  type: "primary" | "secondary" | "warning" | "glass" | "borderOnly" | "error";
  noShadow?: boolean;
  heightAndWidth: string;
  isClicked?: boolean;
}>`
  width: ${(props) => props.heightAndWidth && props.heightAndWidth};
  height: ${(props) => props.heightAndWidth && props.heightAndWidth};
  box-shadow: ${(props) => !props.noShadow && "4px 4px 0px rgba(0, 0, 0, 1)"};
  display: flex;
  border-radius: 100vw;
  justify-content: center;
  align-items: center;
  border: 1px solid ${dark};

  ${(props) =>
    props.type === "primary" &&
    css`
      background: ${mintGreen};
    `}
  ${(props) =>
    props.type === "secondary" &&
    css`
      background: ${blue};
    `}
  ${(props) =>
    props.type === "warning" &&
    css`
      background: ${warning};
    `}
  ${(props) =>
    props.type === "glass" &&
    css`
      color: ${dark};
      box-shadow: unset;
      border: unset;
    `}
  ${(props) =>
    props.type === "borderOnly" &&
    css`
      background-color: ${colorWhite};
      color: ${dark};
    `}
  ${(props) =>
    props.type === "error" &&
    css`
      background-color: ${error};
      color: ${dark};
    `}

    ${(props) =>
    props.isClicked &&
    css`
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 1);
      transform: translate(
        4px,
        4px
      ); // Move the button 4px to the right and 4px down
    `}
`;
