import { Component } from "react";
import styled, { css } from "styled-components";
import { GroupData, SessionData } from "./database.types";
import { SubTitle, Title, TitleBox } from "./commonUI";
import { Button } from "./Button";
import { THEME } from "./theme";
import Icon from "./icon";

export type GameData = {
  playerName: string;
  playGroup: PlayGroups;
  userId: string;
};

export type PlayGroups =
  | "specialPlayer"
  | "groupA"
  | "groupB"
  | "groupC"
  | "groupD";

interface Props {
  sessionData: SessionData | null;
  refreshUserList: () => void;
  removePlayer: (userId: string) => void;
  serverUrl: string;
  setGroupInviteModal: (groupId: number) => void;
  showPlayerList: boolean;
  switchGroup: (groupId: number) => Promise<void>;
  goBack: () => void;
  endGame: () => void;
}

const windowHeight = window.innerHeight;

interface State {
  confirmRemovePlayer: string | null;
  confirmEndGame: boolean;
  showInviteModal: number | null;
}

export class PlayerManagementHost extends Component<Props, State> {
  // Properties to hold the state and configuration of the onboarding process
  onboardingAudioElement: HTMLAudioElement | null = null;

  state: State = {
    confirmRemovePlayer: null,
    showInviteModal: null,
    confirmEndGame: false,
  };

  handleEndGame = () => {
    this.setState({
      confirmEndGame: true,
    });
  };

  renderGroup = (groupInfo: GroupData, index: number) => {
    return (
      <GroupContainer key={index}>
        <GroupNameHeader>
          <GroupNameHeaderLeft>
            <GroupName>{groupInfo.name}</GroupName>
            <GroupCounter>{groupInfo.players?.length || 0}</GroupCounter>
          </GroupNameHeaderLeft>
          <GroupNameHeaderRight>
            <Button
              label={"Invite Player"}
              type={"primary"}
              size="veryVerySmall"
              onClick={() => this.props.setGroupInviteModal(groupInfo.id)}
            />
            <Button
              label={"Join"}
              type={"glass"}
              size="veryVerySmall"
              onClick={() => this.props.switchGroup(groupInfo.id)}
            />
          </GroupNameHeaderRight>
        </GroupNameHeader>
        {groupInfo?.players && groupInfo?.players?.length > 0 ? (
          groupInfo.players.map((player) => (
            <UserName key={player.userId}>
              {player.playerName}
              <Button
                onClick={() => {
                  this.setState({
                    confirmRemovePlayer: player.userId,
                  });
                }}
                label={"Remove"}
                type={"warning"}
                size={"veryVerySmall"}
              />
            </UserName>
          ))
        ) : (
          <NoPlayersMessage>No players in this group</NoPlayersMessage>
        )}
      </GroupContainer>
    );
  };

  render() {
    if (this.state.confirmRemovePlayer) {
      const playerNameToRemove = (() => {
        for (const group of Object.values(
          this.props.sessionData?.groupData || {}
        )) {
          const foundPlayer = group.players?.find(
            (player) => player.userId === this.state.confirmRemovePlayer
          );
          if (foundPlayer) {
            return foundPlayer.playerName;
          }
        }
        return "Unknown Player";
      })();

      return (
        <ConfirmRemovePlayerContainer>
          <TitleBox margin={"5vh 0 2vh 0"}>
            <Title size={1.5}>Remove Player "{playerNameToRemove}"?</Title>
            <SubTitle>This can't be undone</SubTitle>
          </TitleBox>
          <Button
            onClick={() => {
              if (this.state.confirmRemovePlayer) {
                this.props.removePlayer(this.state.confirmRemovePlayer);
                this.setState({
                  confirmRemovePlayer: null,
                });
              }
            }}
            label={"Confirm"}
            type={"primary"}
          />
          <Button
            onClick={() => {
              this.setState({
                confirmRemovePlayer: null,
              });
            }}
            label={"Go Back"}
            type={"secondary"}
          />
        </ConfirmRemovePlayerContainer>
      );
    }
    if (this.state.confirmEndGame) {
      return (
        <ConfirmRemovePlayerContainer>
          <TitleBox margin={"5vh 0 2vh 0"}>
            <Title size={1.5}>End Game For Everyone?</Title>
          </TitleBox>
          <Button
            onClick={() => {
              this.props.endGame();
              this.setState({
                confirmEndGame: false,
              });
            }}
            label={"Confirm"}
            type={"primary"}
          />
          <Button
            onClick={() => {
              this.setState({
                confirmEndGame: false,
              });
            }}
            label={"Go Back"}
            type={"secondary"}
          />
        </ConfirmRemovePlayerContainer>
      );
    }

    const totalPlayers = Object.values(
      this.props.sessionData?.groupData || {}
    ).reduce((acc, group) => acc + (group.players?.length || 0), 0);

    return (
      <PlayerManagementContainer showPlayerList={this.props.showPlayerList}>
        <TopBarContainer>
          <IconBox onClick={this.props.goBack}>
            <Icon name={"arrow_left"} heightAndWidth="4vh" />
          </IconBox>
          <RightSideBox>
            <Button
              onClick={this.handleEndGame}
              label={"End Game"}
              type={"secondary"}
              size={"small"}
            />
            <Button
              onClick={async () => {
                this.props.refreshUserList();
              }}
              label={"Refresh"}
              type={"secondary"}
              size={"small"}
            />
          </RightSideBox>
        </TopBarContainer>
        <TopBar>
          <TitleBox>
            <Title horizontalAlign="left" bold size={1.6}>
              Manage Players & Groups
            </Title>
          </TitleBox>
          {/* <Title size={1.5}>Players ( {totalPlayers} )</Title> */}
        </TopBar>
        <UserListContainer showPlayerList={this.props.showPlayerList}>
          <GroupSections>
            <Title horizontalAlign="left" size={1.7}>
              Only via manual invite
            </Title>
            {this.props.sessionData?.groupData
              .sort((a, b) => a.id - b.id)
              .filter((group) => group.maxPlayers === 0)
              .map((groupInfo, index) => this.renderGroup(groupInfo, index))}
          </GroupSections>
          <GroupSections>
            <Title horizontalAlign="left" size={1.7}>
              New Players auto-distributed to
            </Title>
            {this.props.sessionData?.groupData
              .sort((a, b) => a.id - b.id)
              .filter((group) => group.maxPlayers !== 0)
              .map((groupInfo, index) => this.renderGroup(groupInfo, index))}
          </GroupSections>
        </UserListContainer>
      </PlayerManagementContainer>
    );
  }
}

const PlayerManagementContainer = styled.div<{
  showPlayerList: boolean;
}>`
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 5vh;
  height: 100%;
  position: absolute;
  overflow: hidden;
  bottom: 0px;
  display: flex;
  z-index: -1;
  background: ${THEME().colors.blue};
  ${(props) =>
    props.showPlayerList &&
    css`
      display: flex;
      z-index: 5;
    `};
`;

const UserListContainer = styled.div<{
  showPlayerList: boolean;
}>`
  display: none;
  background: ${THEME().colors.colorWhite};
  z-index: 3;
  flex-direction: column;
  height: 85%;
  margin-top: 2vh;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: 5vw 5vw 0 0;
  box-sizing: border-box;
  transition: bottom 0.3s ease-in-out;
  box-sizing: border-box;
  overflow: auto;

  @media (max-width: 1200px) {
    padding: 5vw;
    padding-top: 4vh;
  }

  @media (min-width: 1201px) {
    padding: 3vw;
    padding-top: 3vh;
  }
  ${(props) =>
    props.showPlayerList &&
    css`
      display: flex;
    `}
`;

const GroupContainer = styled.div`
  width: 100%;
  margin-bottom: 4vh;
`;

const UserName = styled.div`
  color: ${THEME().colors.greyScale400};
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 4vw;

  @media (max-width: 1200px) {
    font-size: 4vw;
  }

  @media (min-width: 1201px) {
    font-size: 2vw;
  }
`;
const NoPlayersMessage = styled.div`
  color: ${THEME().colors.greyScale400};
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 1200px) {
    font-size: 4vw;
  }

  @media (min-width: 1201px) {
    font-size: 2vw;
  }
`;

const GroupSections = styled.div`
  margin-bottom: 3vh;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const GroupNameHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 3vh;
  margin-bottom: 3vh;
`;

const GroupNameHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  grid-gap: 1vw;
`;
const GroupNameHeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  grid-gap: 1vw;
`;
const GroupCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #c5c4c4;
  border-radius: 10vw;
  padding: 1vw 2vw;

  @media (max-width: 1200px) {
    font-size: 4vw;
  }

  @media (min-width: 1201px) {
    font-size: 1.5vw;
  }
`;

const GroupName = styled.div`
  font-weight: bold;
  /* Special Players */
  font-weight: 400;
  color: ${THEME().colors.greyScale};

  @media (max-width: 1200px) {
    font-size: 4.3vw;
  }

  @media (min-width: 1201px) {
    font-size: 1.5vw;
  }
`;

const TopBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 3vw;
  margin-top: 2vh;
  width: 100%;
  padding: 0 5vw;
  box-sizing: border-box;
`;

const ConfirmRemovePlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: ${windowHeight}px;
  grid-gap: 15px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background: #12131b;
`;

const IconBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

const TopBarContainer = styled.div`
  height: 8vh;
  padding: 0 4vw;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const RightSideBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 2vw;
`;
