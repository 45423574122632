import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { GameData, IRLevents } from "./database.types";
import { Button } from "./Button";
import {
  ContentContainer,
  GameCard,
  GameDescription,
  GameTitle,
  SubTitle,
  Title,
  TitleBox,
  magenta,
} from "./commonUI";
import BouncingImages from "./bouncingImagesBackground";
import { THEME } from "./theme";
import { marked } from "marked";

interface Props {
  serverUrl: string;
}

const StartJoinSession: React.FC<Props> = ({ serverUrl }) => {
  const [games, setGames] = useState<GameData[]>([]);
  const [irlEvents, setIrlEvents] = useState<IRLevents[]>([]);
  const [eventsFetched, setEventsFetched] = useState(false);
  const [parsedDescriptions, setParsedDescriptions] = useState<{
    [key: string]: string;
  }>({});
  const navigate = useNavigate();
  const gamesFetched = useRef(false);

  useEffect(() => {
    if (!gamesFetched.current) {
      const getAllGames = async () => {
        const response = await fetch(`${serverUrl}/get-all-games`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setGames(data.games.data);
        gamesFetched.current = true; // Mark fetch as completed
      };

      getAllGames();
    }
    if (irlEvents?.length === 0 && !eventsFetched) {
      const getAllIRLevents = async () => {
        const response = await fetch(`${serverUrl}/get-all-events`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setIrlEvents(data.events.data);
        setEventsFetched(true); // Mark fetch as completed
      };

      getAllIRLevents();
    }
    const parseDescriptions = async () => {
      const descriptions: { [key: string]: string } = {};
      for (const event of irlEvents) {
        descriptions[event.id] = await marked.parse(event.description ?? "");
      }
      setParsedDescriptions(descriptions);
    };

    if (irlEvents.length > 0) {
      parseDescriptions();
    }
  }, [games, irlEvents, serverUrl]);

  const handleGoToCreatorSuite = () => {
    navigate("/auth");
  };

  const handleLaunchGame = async (gameId: string) => {
    const response = await fetch(
      `${serverUrl}/create-session-from-game-as-host?gameId=${gameId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    const sessionId = data.sessionId;
    navigate(`/host?sessionId=${sessionId}`);
  };

  return (
    <MainContainer>
      <TopContainer>
        <TitleContainer>
          <Title bold horizontalAlign="left" size={4}>
            BAM
          </Title>
          <SubTitle horizontalAlign="left" size={2}>
            Alternate Reality Audio Games
          </SubTitle>
        </TitleContainer>
        <BouncingImages />
      </TopContainer>
      <AvailableGamesContainer>
        <TitleBox>
          <Title horizontalAlign="left" size={1.7}>
            Available Games
          </Title>
          <SubTitle horizontalAlign="left" color={"greyScale400"} size={1.5}>
            Host your own games instantly with anyone!
          </SubTitle>
        </TitleBox>
        <GameCardsContainer>
          {games != null &&
            games?.map((game: GameData) => (
              <GameCard key={game.gameId}>
                <GameCardTopBar>
                  <GameTitle>{game.name}</GameTitle>
                  <Price>
                    {game.price == null ? "Free" : `$${game.price} / player`}
                  </Price>
                </GameCardTopBar>
                <GameDescription>{game.description}</GameDescription>
                <GameCardBottomBar>
                  <GameCardBottomBarLeft>
                    <GameCardInfo>
                      Players <strong>{game.playerRecommendations}</strong>
                    </GameCardInfo>
                    <GameCardInfo>
                      Duration <strong>{game.duration} min</strong>
                    </GameCardInfo>
                  </GameCardBottomBarLeft>
                  <Button
                    type={"borderOnly"}
                    label={"Start Game"}
                    size={"medium"}
                    onClick={() => handleLaunchGame(game.gameId)}
                    width={"40vw"}
                  />
                </GameCardBottomBar>
              </GameCard>
            ))}
        </GameCardsContainer>
      </AvailableGamesContainer>
      <AvailableGamesContainer>
        <TitleBox>
          <Title horizontalAlign="left" size={1.7}>
            Next In-Person Events
          </Title>
          <SubTitle horizontalAlign="left" color={"greyScale400"} size={1.5}>
            We’re regularly hosting the game. Come by!
          </SubTitle>
        </TitleBox>
        <GameCardsContainer>
          {irlEvents != null &&
            irlEvents?.map((event: IRLevents) => (
              <GameCard key={event.id}>
                <GameCardTopBar>
                  <GameTitle>{event.title}</GameTitle>
                  <Price>
                    {event.price == null
                      ? "Free"
                      : `${event.price_currency}${event.price}`}
                    {event.price_description && (
                      <PriceDescription>
                        {event.price_description}
                      </PriceDescription>
                    )}
                  </Price>
                </GameCardTopBar>
                <GameDescription>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: parsedDescriptions[event.id] || "",
                    }}
                  />
                </GameDescription>
                <GameCardBottomBar>
                  <GameCardBottomBarLeft>
                    {event.event_date && (
                      <GameCardInfo>
                        <>
                          When?{" "}
                          <DateContainer>
                            <strong>
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }).format(new Date(event.event_date))}
                            </strong>
                            {event.event_time_of_day && (
                              <DateSubtitle>
                                at{" "}
                                {event.event_time_of_day?.split(":")[0] +
                                  ":" +
                                  event.event_time_of_day?.split(":")[1]}
                              </DateSubtitle>
                            )}
                          </DateContainer>
                        </>
                      </GameCardInfo>
                    )}
                    <GameCardInfo>
                      Where <strong>{event.city}</strong>
                    </GameCardInfo>
                  </GameCardBottomBarLeft>
                  {event.more_info_link != null ? (
                    <Button
                      type={"borderOnly"}
                      label={"More Info"}
                      size={"medium"}
                      onClick={() => {
                        if (event.more_info_link) {
                          window.open(event.more_info_link, "_blank");
                        }
                      }}
                      width={"40vw"}
                    />
                  ) : null}
                </GameCardBottomBar>
              </GameCard>
            ))}
        </GameCardsContainer>
      </AvailableGamesContainer>
      <CreateOwnGameContainer>
        <Title bold horizontalAlign="left" size={2.5}>
          Create your own audio experiences
        </Title>
        <SubTitle horizontalAlign="left" color={"greyScale"} size={1.5}>
          You can upload your own audio files and lead friends, groups, clients
          or students through a time synced audio experience. <br /> It’s for
          free & you can even charge for it via our internal payment system.
        </SubTitle>
        <BottomSection>
          <Button
            label="Create your own game"
            size="medium"
            type="primary"
            onClick={handleGoToCreatorSuite}
            width="100%"
          />
        </BottomSection>
      </CreateOwnGameContainer>
    </MainContainer>
  );
};

export default StartJoinSession;

const TopArea = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  width: -webkit-fill-available;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    margin: 0vw 3vw 5vw 3vw;
  }
  @media (min-width: 1201px) {
    margin: 1vw;
  }
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  grid-gap: 10px;
  height: 100%;
  box-sizing: border-box;
`;

const AvailableGamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin-bottom: 3vh;
  grid-gap: 3vh;
  box-sizing: border-box;
  padding: 5vw;
`;

const GameCardsContainer = styled.div`
  display: flex;
  grid-gap: 20px;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
`;

const GameCardTopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  grid-gap: 10px;
`;
const GameCardBottomBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  grid-gap: 10px;
  @media (max-width: 1200px) {
    margin-top: 1.5vh;
  }
  @media (min-width: 1201px) {
    margin-top: 1vh;
  }
`;
const GameCardBottomBarLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  grid-gap: 5vw;
`;

const Price = styled.div`
  color: ${THEME().colors.dark};
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  text-align: right;

  @media (max-width: 1200px) {
    font-size: 5vw;
  }
  @media (min-width: 1201px) {
    font-size: 1.5vw;
  }
`;
const PriceDescription = styled.div`
  color: ${THEME().colors.greyScale};
  display: flex;
  justify-content: flex-end;
  text-align: right;
  font-weight: 300;

  @media (max-width: 1200px) {
    font-size: 3.5vw;
  }
  @media (min-width: 1201px) {
    font-size: 1.5vw;
  }
`;
const GameCardInfo = styled.div`
  color: ${THEME().colors.greyScale};
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    font-size: 4vw;
  }
  @media (min-width: 1201px) {
    font-size: 1.5vw;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  align-items: space-between;
  grid-gap: 10px;
  width: 100%;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: fit-content;
  overflow: hidden;
  overflow-y: auto;
`;

const LogoText = styled.div`
  font-size: 20vw; // Adjust size as needed
  margin-bottom: 10vh;
  margin-top: 10vh;
  color: #12131b;
  font-weight: 600;
  /* background: linear-gradient(266.77deg, #6ae394 -18.13%, #fafafa 104.34%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 300px;
  grid-gap: 1vh;
  box-sizing: border-box;
  background: ${magenta};
  position: relative;
`;

const NextEventsContainer = styled.div``;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  grid-gap: 1vh;
  padding: 5vw;
  box-sizing: border-box;
  height: 100%;
  z-index: 2;
  position: absolute;
`;

const NoBold = styled.span`
  font-weight: 300;
  display: inline;
  margin-left: 2px;
`;

const DateContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

const DateSubtitle = styled.div`
  color: ${THEME().colors.greyScale};
  display: flex;
  justify-content: flex-end;
  text-align: right;
  font-weight: 300;

  @media (max-width: 1200px) {
    font-size: 3.8vw;
  }
  @media (min-width: 1201px) {
    font-size: 1.5vw;
  }
`;

const CreateOwnGameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  grid-gap: 4vw;
  box-sizing: border-box;
  padding: 10vw;
  background: ${THEME().colors.blue};
`;
