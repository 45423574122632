import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { Button } from "./Button";
import {
  ContentContainer,
  SubTitle,
  TextField,
  Title,
  TitleBox,
} from "./commonUI";

interface Props {
  serverUrl: string;
}

const JoinGameWithSessionId: React.FC<Props> = ({ serverUrl }) => {
  const [sessionId, setSessionId] = useState("");
  const navigate = useNavigate();

  const handleJoin = () => {
    if (sessionId.length > 0) {
      navigate("/player?sessionId=" + sessionId);
    }
  };

  return (
    <MainContainer>
      <ContentContainer>
        <JoinSessionBox>
          <TitleBox margin={"0 0 20px 0"}>
            <Title size={2}>Join a session</Title>
            <SubTitle>Type in the sessionId</SubTitle>
          </TitleBox>
          <TextField
            placeholder="SessionId"
            value={sessionId}
            onChange={(e) => setSessionId(e.target.value)}
          />
          <Button
            onClick={handleJoin}
            type="primary"
            label="Join Game"
            width="fullWidth"
            size="large"
          />
        </JoinSessionBox>
      </ContentContainer>
    </MainContainer>
  );
};

export default JoinGameWithSessionId;

const JoinSessionBox = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  align-items: center;
  padding: 30px;
  box-sizing: border-box;
  grid-gap: 30px;
  width: 100%;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
`;
