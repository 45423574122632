import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { LoadingScreen } from "./LoadingScreen";

const windowHeight = window.innerHeight;

const DynamicRoute = ({ serverUrl }: { serverUrl: string }) => {
  const { shortCode } = useParams<{ shortCode: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    const checkDatabase = async () => {
      try {
        const response = await axios.post(
          `${serverUrl}/api/shortlinks/${shortCode}`
        );
        if (response.status === 200) {
          const data = response.data;
          const url = new URL(data.originalUrl);
          const pathAndQuery = url.pathname + url.search;
          navigate(pathAndQuery);
        } else {
          navigate("/not-found");
        }
      } catch (error) {
        console.error("Error checking database:", error);
        // navigate("/error");
      }
    };

    checkDatabase();
  }, [shortCode, navigate, serverUrl]);

  return <LoadingScreen height={windowHeight} />;
};

export default DynamicRoute;
