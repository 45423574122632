import React, { Component } from "react";
import io from "socket.io-client";
import QRCode from "react-qr-code";
import styled, { css } from "styled-components";
import debounce from "lodash/debounce";
import axios from "axios";
import { OnboardingFlowPlayer } from "./OnboardingFlowPlayer";
import {
  GameData,
  PlayGroups,
  PlayerManagementHost,
} from "./PlayerManagementHost";
import { Button } from "./Button";
import { RoundButton } from "./RoundButton";
import { TroubleShooting } from "./TroubleShooting";
import {
  ButtonColumn,
  ContentContainer,
  MainContainer,
  SubTitle,
  Title,
  TitleBox,
  dark,
  greyScale,
  mintGreen,
} from "./commonUI";
import { LoadingScreen } from "./LoadingScreen";
import {
  GroupData,
  SessionData,
  SessionDataTimeKeepers,
} from "./database.types";
import BouncingImages from "./bouncingImagesBackground";
import { THEME } from "./theme";
import { LoadingIndicator } from "./LoadingIndicator";
import Icon from "./icon";
import Donations from "./components/donations";

interface GameSessionProps {
  serverUrl: string;
  userRole: "player" | "host";
}

interface State {
  hasInteractedWithAudioPlay: boolean;
  gameAudio: Blob | null;
  audioState: "playing" | "paused" | "waitingToPlay";
  gameId: string | null;
  sessionId: string | null;
  groupId: number | null;
  sessionIdValid: boolean;
  uiState: "loading" | "onboarding" | "game" | "enterSessionId" | "gameEnd";
  confirmHardReset: boolean;
  confirmRestart: boolean;
  downloadProgress: number | null;
  currentGameTimeDisplay: number;
  participants: GameData[];
  playGroup?: PlayGroups | null; // Add this line
  currentSession: {};
  currentSessionData: SessionData | null;
  currentSessionTimeKeepingData?: SessionDataTimeKeepers;
  downloadingGameAudioState: "pristine" | "running" | "finished" | "error";
  currentErrorMessage: string | null;
  leaveAndResetConfirmation: boolean;
  playerName: string | null;
  showTroubleShooting: boolean;
  isReentering: boolean;
  isResyncSuccessful: boolean;
  showGroupInviteModal: number | null;
  shareLinkCopied: boolean;
  showPlayerManagement: boolean;
  isPreviewMode: boolean;
  confirmStartGame?: boolean;
  audioDuration: number | null;
  playerCount: number;
  latencies: number[];
  clientServerTimeDelta: number | null;
}
interface PlayData {
  playTime: number;
  serverTime: number;
}

const windowHeight = window.innerHeight;
let assetsServerUrl: string =
  "https://cjzbftkyxnbpyozbgprl.supabase.co/storage/v1/object/public/";

class Gamesession extends Component<GameSessionProps, State> {
  socket: any; //SocketIOClient.Socket;
  audioRef: React.RefObject<HTMLAudioElement>;
  audioElement: HTMLAudioElement | null = null;
  gameAudio: any;
  updateGameTimeInterval: any;

  constructor(props: GameSessionProps) {
    super(props);
    this.socket = io(this.props.serverUrl, {
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: Infinity,
    });
    this.audioRef = React.createRef();
    this.state = {
      hasInteractedWithAudioPlay: false,
      audioState: "paused" as "playing" | "paused",
      gameAudio: null,
      sessionId: null, //this.generateRandomString(),
      gameId: null, //this.generateRandomString(),
      groupId: null,
      sessionIdValid: false,
      uiState: "loading",
      confirmHardReset: false,
      confirmRestart: false,
      downloadProgress: 0,
      currentGameTimeDisplay: 0,
      participants: [],
      playGroup: null,
      currentSession: {},
      currentSessionData: null,
      currentSessionTimeKeepingData: undefined,
      downloadingGameAudioState: "pristine",
      currentErrorMessage: null,
      leaveAndResetConfirmation: false,
      playerName: null,
      showTroubleShooting: false,
      isReentering: false,
      isResyncSuccessful: false,
      showGroupInviteModal: null,
      shareLinkCopied: false,
      showPlayerManagement: false,
      isPreviewMode: false,
      confirmStartGame: false,
      audioDuration: null,
      playerCount: 0,
      latencies: [],
      clientServerTimeDelta: null,
    };
  }

  currentTimeInterval: any; // Holds the interval ID for updating the current time
  currentTimeIntervalPlayer: any; // Holds the interval ID for updating the current time
  checkForCurrentGameTimeInterval: any; // is the interval that periodically checks on the server if the time is off and adjusts

  async componentDidMount() {
    this.setupSocketListeners();
    this.setState({
      uiState: "loading",
      currentErrorMessage: null,
    });

    // Analyze the URL query parameters
    let sessionIdFromQuery: string | null = null;
    let gameIdFromQuery: string | null = null;
    let groupIdFromQuery: number | null = null;
    const urlParams = new URLSearchParams(window.location.search);
    sessionIdFromQuery = urlParams.get("sessionId") ?? "";
    gameIdFromQuery = urlParams.get("gameId") ?? "";

    if (urlParams.get("groupId")) {
      groupIdFromQuery = parseFloat(urlParams.get("groupId")!);
    }

    this.setState({
      sessionId: sessionIdFromQuery ?? null,
      gameId: gameIdFromQuery ?? null,
      groupId: groupIdFromQuery ?? null,
    });

    // Load SessionData
    let sessionData = null;
    let sessionTimeKeepingData = null;
    if (sessionIdFromQuery) {
      sessionData = await this.getSessionData(sessionIdFromQuery);
      this.socket.emit("joinSession", { sessionId: sessionIdFromQuery });
      // Load SessionTimeKeepingData

      sessionTimeKeepingData = await this.getCurrentGameTime();
      this.socket.emit("joinSession", { sessionId: sessionIdFromQuery });
    } else {
      return;
    }

    if (!sessionData) {
      return;
    }

    if (this.props.userRole === "host") {
      // Preparing all session data
      await this.shouldResetGame(sessionIdFromQuery);
      this.refreshUserList();
      const players = this.countTotalPlayers();
      this.setState({
        playerCount: players,
      });

      this.updateGameAudioDisplay(sessionTimeKeepingData.newTime);

      this.setState({ uiState: "game", audioState: "paused" });
      if (sessionTimeKeepingData.gameIsRunning) {
        this.setState({ audioState: "playing", isReentering: true });
      }
      await this.downloadAudioFiles(groupIdFromQuery ?? 1);
      this.prepareAudio();
      if (this.audioElement) {
        // this.startEndtimeListener();
        this.audioElement.currentTime = sessionTimeKeepingData.newTime;
      }
      // needs something that starts a regular ping to the server but only if its running
    }

    if (this.props.userRole === "player") {
      const shouldReset = await this.shouldResetGame();
      if (sessionIdFromQuery) {
        this.setState({
          sessionId: sessionIdFromQuery,
          sessionIdValid: true,
        });
      }

      const userId = localStorage.getItem("userId");

      let newPlayer = true;
      if (userId) {
        const sessionData = this.state.currentSessionData;
        if (sessionData && sessionData.groupData) {
          for (const group of sessionData.groupData) {
            if (group?.players?.some((player) => player.userId === userId)) {
              newPlayer = false;
              break;
            }
          }
        }
      }

      let gameData;
      if (!newPlayer && !shouldReset) {
        gameData = await this.joinGame();
      }

      if (gameData) {
        newPlayer = gameData.newPlayer;
      }

      if (newPlayer || shouldReset) {
        this.setState({ uiState: "onboarding" });
      } else {
        const gameIsRunning = sessionTimeKeepingData?.gameIsRunning ?? false;

        this.setState({
          uiState: "game",
          isReentering: true,
          audioState: gameIsRunning ? "playing" : "paused",
        });
        if (gameIsRunning && this.state.hasInteractedWithAudioPlay) {
          this.startCountAndUpdatePlayTime();
        }
        // added here so that in case the ws does not work it would still try to periodically fetch the times
        this.startCheckcurrentTimeInterval();
      }
    }
    this.prepareAudio();
    await this.connectToSession();
    document.addEventListener("visibilitychange", async () => {
      if (document.visibilityState === "hidden") {
      } else {
        await this.connectToSession();
      }
    });
  }

  componentWillUnmount() {
    this.socket.off("play", this.handlePlay);
    this.socket.off("pause", this.handlePause);
    if (this.audioElement) {
      this.audioElement.pause();
      this.audioElement.src = ""; // This releases the object URL
    }

    // Leave the socket room
    if (this.state.sessionId) {
      this.socket.emit("leaveSession", { sessionId: this.state.sessionId });
    }
  }

  async connectToSession() {
    this.socket.emit("joinSession", { sessionId: this.state.sessionId });
    try {
      if (this.state.isPreviewMode || !this.state.hasInteractedWithAudioPlay) {
      } else {
        await this.jumpToCurrentGameTime();
      }
    } catch (e) {
      console.log(e + "erro1r");
    }
  }

  setupSocketListeners = () => {
    this.socket.on("connect", async () => {
      this.socket.on("play", this.handlePlay);
      this.socket.on("pause", this.handlePause);
      if (this.props.userRole === "host") {
        this.socket.on("addedPlayer", this.handleAddedPlayer);
        this.socket.on("removedPlayer", this.handleRemovedPlayer);
      }
      if (this.props.userRole === "player") {
        this.socket.on("endAudio", this.handleEnd);
      }

      await this.connectToSession();
    });

    this.socket.on("disconnect", async (reason: any) => {
      console.log(`Socket disconnected: ${reason}`);
      // the disconnection was initiated by the server, you need to reconnect manually
      this.setupSocketListeners();
      // else the socket will automatically try to reconnect
    });

    this.socket.on("reconnect", (attemptNumber: any) => {
      console.log(`Socket reconnected after ${attemptNumber} attempts`);
    });
  };

  startCheckcurrentTimeInterval = () => {
    this.stopCheckcurrentTimeInterval();
    this.checkForCurrentGameTimeInterval = setInterval(() => {
      if (!this.state.isPreviewMode && this.state.hasInteractedWithAudioPlay) {
        this.jumpToCurrentGameTime();
      }
    }, 5000);
  };

  stopCheckcurrentTimeInterval = () => {
    clearInterval(this.checkForCurrentGameTimeInterval);
  };

  countTotalPlayers = () => {
    const { currentSessionData } = this.state;
    if (!currentSessionData || !currentSessionData.groupData) {
      return 0;
    }
    return currentSessionData.groupData.reduce((total, group) => {
      return total + (group.players ? group.players.length : 0);
    }, 0);
  };

  handleAddedPlayer = (sessionData: SessionData) => {
    this.setState({ currentSessionData: sessionData });

    const players = this.countTotalPlayers();
    this.setState({
      playerCount: players,
    });
  };
  handleRemovedPlayer = async (userId: string) => {
    await this.getSessionData();
    const players = this.countTotalPlayers();
    this.setState({
      playerCount: players,
    });
  };

  calculateRequestOffSet = (serverTime: number, clientTime: number) => {
    const adjustForRequestPing = (clientTime - serverTime) / 1000;
    return adjustForRequestPing;
  };

  getCurrentGameTime = async () => {
    const clientSendTime = Date.now() / 1000;
    const response = await fetch(
      `${this.props.serverUrl}/check-currentGameTime?sessionId=${this.state.sessionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const clientReceiveTime = Date.now() / 1000;
    const responseData = await response.json();
    const { currentGameTimeForSync, gameIsRunning } = responseData;

    const roundTripTime = clientReceiveTime - clientSendTime;
    const latency = roundTripTime / 2;
    // Update latencies array
    this.setState((prevState) => ({
      latencies: [...prevState.latencies.slice(-4), latency].sort(
        (a, b) => a - b
      ),
    }));

    // Calculate median and standard deviation
    const { latencies } = this.state;
    const medianLatency = latencies[Math.floor(latencies.length / 2)];
    const standardDeviation = Math.sqrt(
      latencies.reduce(
        (sum, lat) => sum + Math.pow(lat - medianLatency, 2),
        0
      ) / latencies.length
    );

    // Filter out high latencies and calculate average
    const filteredLatencies = latencies.filter(
      (lat) => Math.abs(lat - medianLatency) <= standardDeviation
    );
    const averageLatency =
      filteredLatencies.reduce((sum, lat) => sum + lat, 0) /
      filteredLatencies.length;

    const newTime = currentGameTimeForSync + averageLatency;

    const currentPlayTime = this.audioElement?.currentTime ?? 0;
    const playerOffset = Math.abs(newTime - currentPlayTime);

    return {
      gameIsRunning,
      newTime,
      playerOffset,
    };
  };
  jumpToCurrentGameTime = async (forceUpdate?: boolean) => {
    try {
      let { gameIsRunning, newTime, playerOffset } =
        await this.getCurrentGameTime();

      if (this.audioElement) {
        this.updateGameAudioDisplay(newTime);
        if (!gameIsRunning) {
          if (forceUpdate) {
            this.audioElement.currentTime = newTime;
          }

          if (
            !this.audioElement.paused &&
            this.state.hasInteractedWithAudioPlay
          ) {
            this.audioElement?.pause();
            this.setState({ audioState: "paused" });
          }
        } else {
          if (this.state.uiState === "game") {
            const bufferForOffset = 2;
            if (bufferForOffset < playerOffset || forceUpdate) {
              this.audioElement.currentTime = newTime;
            }

            this.setState({ audioState: "playing" });
            if (
              !this.state.hasInteractedWithAudioPlay ||
              this.state.downloadingGameAudioState !== "finished"
            ) {
              return;
            } else {
              this.audioElement?.play();
            }
          }
          this.startCountAndUpdatePlayTime(true);
        }
      }
      if (forceUpdate) {
        this.setState({ isResyncSuccessful: true });
        setTimeout(() => {
          this.setState({ isResyncSuccessful: false });
        }, 3000);
      }

      return;
    } catch (err) {
      console.log(err);
      return;
    }
  };

  updateGameAudioDisplay = (timeToUpdate: number) => {
    this.setState({
      currentGameTimeDisplay: timeToUpdate,
    });
  };

  prepareAudio = () => {
    // This method is triggered by the user's first interaction.
    // Here, you can load your audio, set it up, but don't play it yet.
    // This is where you "unlock" the audio for later playback.
    if (this.audioElement) {
      this.audioElement.load(); // Or any other preparation steps.
    }
  };

  generateRandomString = () => {
    return Math.random().toString(36).substring(2, 7);
  };

  checkForGameFile = async () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("gameSessionDB");

      request.onerror = (event) => {
        console.error("Error opening IndexedDB:", event);
        reject("Error opening IndexedDB.");
      };

      request.onsuccess = (event) => {
        const db = request.result;
        const transaction = db.transaction(["sessions"], "readonly");
        const objectStore = transaction.objectStore("sessions");
        const gameFileRequest = objectStore.get("gameFile");

        gameFileRequest.onerror = (event) => {
          console.error("Error fetching gameFile from IndexedDB:", event);
          reject("Error fetching gameFile from IndexedDB.");
        };

        gameFileRequest.onsuccess = () => {
          if (gameFileRequest.result) {
            resolve(true);
          } else {
            resolve(false);
          }
        };
      };
    });
  };

  shouldResetGame = async (sessionIdOptional?: string) => {
    const sessionId = sessionIdOptional ?? this.state.sessionId;
    const resetResponse = await fetch(
      `${this.props.serverUrl}/check-if-reset-happened?sessionId=${sessionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let lastResetTime: string | null = (await resetResponse.text()) ?? null;

    if (lastResetTime === "null") {
      lastResetTime = null;
    }

    let lastAcknowledgedResetTime: string | null = localStorage.getItem(
      "lastAcknowledgedResetTime"
    );

    if (lastAcknowledgedResetTime === "null") {
      lastAcknowledgedResetTime = null;
    }

    let shouldReset = false;
    if (
      (lastResetTime &&
        lastAcknowledgedResetTime &&
        lastAcknowledgedResetTime < lastResetTime) ||
      (lastResetTime != null && lastAcknowledgedResetTime == null)
    ) {
      shouldReset = true;
      localStorage.setItem("lastAcknowledgedResetTime", lastResetTime);
    }
    if (shouldReset) {
      localStorage.removeItem("userId");
      const databaseName = "gameSessionDB";
      const version = 2;
      const db = await this.openIndexedDB(
        databaseName,
        version,
        "sessions",
        "id"
      );
      const storeName = "sessions";
      const fileName = this.state.sessionId;
      await this.deleteFromIndexedDB(db, storeName, fileName);
    }

    return shouldReset;
  };

  getSessionData = async (sessionIdFromQuery?: string) => {
    const sessionId = sessionIdFromQuery ?? this.state.sessionId;
    const response = await fetch(
      `${this.props.serverUrl}/get-current-session?sessionId=${sessionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    // Log the raw response text
    const sessionData = await response.json();

    this.setState({
      currentSessionData: sessionData,
    });

    return sessionData;
  };

  fakePlayPause = () => {
    this.audioElement?.play();
    this.audioElement?.pause();
  };

  ////
  /// Game Setup Functions
  ////

  downloadAudioFiles = async (playGroup?: number, forceUpdate?: boolean) => {
    try {
      this.setState({
        downloadingGameAudioState: "running",
        downloadProgress: 0,
      });
      const databaseName = "gameSessionDB";
      const version = 2;
      const storeName = "sessions";
      const fileName = this.state.sessionId;

      const db = await this.openIndexedDB(
        databaseName,
        version,
        storeName,
        "id"
      );

      let gameAudioData = await this.readFromIndexedDB(db, storeName, fileName);
      let gameAudioBlob = gameAudioData?.blob;

      if (!gameAudioBlob || forceUpdate) {
        const group = this.state.currentSessionData?.groupData.find(
          (g) => g.id === playGroup
        );

        let urlToCheck = group?.audioFileUrl;

        if (urlToCheck) {
          const response = await axios.get(urlToCheck, {
            responseType: "blob",
            onDownloadProgress: (progressEvent) => {
              const total = progressEvent.total;
              const current = progressEvent.loaded;
              const percentage = Math.floor((current / (total ?? 1)) * 100);
              this.setState({
                downloadProgress: percentage,
              });
            },
          });
          gameAudioBlob = await response.data;

          // Check if the download is complete before saving to IndexedDB
          // if (this.state.downloadProgress === 100) {
          try {
            await this.writeToIndexedDB(db, storeName, {
              id: fileName,
              blob: gameAudioBlob,
            });
          } catch (e) {
            console.error("Error writing to IndexedDB:", e);
          }
        } else if (!urlToCheck) {
          throw new Error("No URL to audio file available");
        }
        // }
      }

      this.setState({
        gameAudio: gameAudioBlob,
      });

      if (gameAudioBlob) {
        const audioUrl = URL.createObjectURL(gameAudioBlob);
        if (!this.audioElement) {
          this.audioElement = new Audio(audioUrl);
          this.audioElement.preload = "auto";
          this.gameAudio = this.audioElement;
        } else {
          this.audioElement.src = audioUrl;
        }
        this.audioElement.addEventListener("loadedmetadata", () => {
          this.setState({
            audioDuration: this.audioElement?.duration ?? null,
            downloadingGameAudioState: "finished",
            downloadProgress: 100,
          });
        });
      }

      return this.gameAudio;
    } catch (error) {
      this.setState({
        downloadingGameAudioState: "error",
        downloadProgress: null,
        currentErrorMessage: `Failed to download audio files: ${error}`,
      });
      console.error("Failed to download audio files:", error);
    }
  };

  joinGame = async () => {
    // check if user already exists
    // this.setState({ uiState: "downloading" });
    // this.audioElement?.play();
    // this.audioElement?.pause();
    let userId;
    let shouldReset = false;
    userId = localStorage.getItem("userId");
    shouldReset = await this.shouldResetGame();

    // if not, register new user for current session
    if (!userId || shouldReset) {
      // Register new user
      userId = this.generateRandomString();
    }
    // register the user for the session (e.g. the socket.io connection)

    let urlToJoin = `${this.props.serverUrl}/user-joins-session?sessionId=${this.state.sessionId}&userId=${userId}`;

    if (this.state.groupId) {
      urlToJoin += `&groupId=${this.state.groupId}`;
    }

    const response = await fetch(urlToJoin, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      return;
    }
    localStorage.setItem("userId", userId);
    const responseData = await response.json();
    const sessionData = responseData.sessionData;
    this.setState({
      currentSession: sessionData,
    });

    const playerData = responseData.playerData;
    const playerName = playerData.playerName;
    this.setState({
      playerName: playerName,
    });
    const newPlayer = responseData.newPlayer;
    const playGroup = playerData.playerGroup;
    // if yes, check if audio file exists
    let audioElement = this.gameAudio;
    if (!audioElement || newPlayer || shouldReset) {
      if (newPlayer) {
        const databaseName = "gameSessionDB";
        const version = 2;
        const db = await this.openIndexedDB(
          databaseName,
          version,
          "sessions",
          "id"
        );
        const storeName = "sessions";
        const fileName = this.state.sessionId;
        await this.deleteFromIndexedDB(db, storeName, fileName);
      }
      this.downloadAudioFiles(playGroup);
    }

    return responseData;
  };

  startGameAsPlayer = async () => {
    this.setState({
      uiState: "game",
      hasInteractedWithAudioPlay: true,
    });
    // if yes, continue to game
    // if game is running, play audio at game time
    this.jumpToCurrentGameTime(true);
  };

  assignPlayGroup = async () => {
    try {
      const response = await fetch(
        `${this.props.serverUrl}/assign-play-group?sessionId=${this.state.sessionId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data: GameData = (await response.json()) as GameData;

      const gameData = {
        playerName: data.playerName,
        playGroup: data.playGroup,
      };

      localStorage.setItem("gameData", JSON.stringify(gameData));

      return data.playGroup;
    } catch (e) {}
  };

  ////
  /// Host control functions
  ////

  handleAudioChange = debounce((value: number) => {
    this.gameAudio.currentTime = value;
    this.stopCountAndUpdatePlayTime();
    this.stopCheckcurrentTimeInterval();
    this.setState({
      currentGameTimeDisplay: value,
      isPreviewMode: true,
    });
  }, 50); //

  confirmPlayTimeChange = async (state: "play" | "pause") => {
    this.setState({
      isPreviewMode: false,
      confirmStartGame: false,
    });

    const data = {
      time: this.gameAudio.currentTime,
      startGame: state === "play" ? true : false,
      sessionId: this.state.sessionId,
    };

    await fetch(`${this.props.serverUrl}/update-game-time`, {
      method: "POST", // Changed to POST
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // Added data to the body
    });
    // this.socket.emit("updateGameTime", {
    //   time: this.state.currentGameTimeDisplay,
    //   startGame: state === "play" ? true : false,
    //   sessionId: this.state.sessionId,
    // });
  };

  cancelPlayTimeChange = async () => {
    this.setState({
      isPreviewMode: false,
      confirmStartGame: false,
    });

    this.jumpToCurrentGameTime(true);
  };

  controlPlay = async () => {
    this.setState({
      hasInteractedWithAudioPlay: true,
    });
    if (!this.state.isPreviewMode) {
      this.setState({
        isPreviewMode: true,
      });
    } else {
      this.setState({
        audioState: "playing",
      });
      this.gameAudio.play();
      this.audioElement?.play();
      this.startCountAndUpdatePlayTime();
    }
  };

  controlPause = () => {
    this.gameAudio.pause();
    this.setState({ audioState: "paused" });
    this.stopCountAndUpdatePlayTime();
    if (!this.state.isPreviewMode) {
      this.setState({
        isPreviewMode: true,
      });
    }
  };
  controlRestart = () => {
    this.setState({ isPreviewMode: true });
    this.stopCountAndUpdatePlayTime();
    this.audioElement?.pause();
    this.gameAudio.currentTime = 0;
    this.setState({ audioState: "paused", currentGameTimeDisplay: 0 });
    if (this.audioElement) {
      this.audioElement.currentTime = 0;
    }
  };
  controlHardReset = async () => {
    if (!this.state.confirmHardReset) {
      this.setState({ confirmHardReset: true });
      return;
    } else {
      this.setState({ confirmHardReset: false });
    }
    this.stopCountAndUpdatePlayTime();
    this.setState({ audioState: "paused" });
    this.socket.emit("hardReset", { sessionId: this.state.sessionId });
    this.socket.emit("pause", { time: 0, sessionId: this.state.sessionId });
    // if (this.updateGameTimeInterval) {
    //   clearInterval(this.updateGameTimeInterval);
    //   this.updateGameTimeInterval = null; // Reset the interval ID to prevent memory leaks
    // }
    if (this.audioElement) {
      this.audioElement.currentTime = 0;
    }

    await fetch(
      `${this.props.serverUrl}/hard-reset-session?sessionId=${this.state.sessionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    this.getSessionData();
  };

  ////
  /// Audio Play event handlers
  ////

  handlePlay = async (data: PlayData) => {
    this.setState({ uiState: "game", audioState: "playing" });
    if (this.audioElement && this.state.hasInteractedWithAudioPlay) {
      this.jumpToCurrentGameTime(true);
      this.startCountAndUpdatePlayTime();
      this.startCheckcurrentTimeInterval();
      if (this.state.hasInteractedWithAudioPlay) {
        this.audioElement.onplay = null;
        this.audioElement.onpause = () => {
          if (this.state.audioState === "playing") {
            this.jumpToCurrentGameTime(true);
          }
        };
      }
    }
  };
  handleEnd = async (data: PlayData) => {
    if (this.audioElement) {
      this.audioElement.pause();
      this.audioElement.onplay = () => {
        this.audioElement?.pause();
      };
    }
    this.setState({ audioState: "paused", uiState: "gameEnd" });
  };
  handlePause = () => {
    if (this.state.hasInteractedWithAudioPlay) {
      this.setState({ audioState: "paused" });
      if (this.gameAudio) {
        this.gameAudio.pause();
      }
      if (this.audioElement) {
        this.audioElement?.pause();
        this.audioElement.onpause = null;
        this.audioElement.onplay = () => {
          if (this.state.audioState !== "playing") {
            this.audioElement?.pause();
          }
        };
        this.stopEndtimeListener();
      }
      // Clear the interval on pause
      this.stopCountAndUpdatePlayTime();
    }
  };

  //////

  startCountAndUpdatePlayTime = (fakeUpdate?: boolean) => {
    // Set up a new interval to count for the player display timer
    if (this.currentTimeInterval) {
      clearInterval(this.currentTimeInterval);
    }
    let fakeUpdateTime = this.audioElement?.currentTime ?? 0;
    this.currentTimeInterval = setInterval(() => {
      if (this.audioElement) {
        let currentTime = this.audioElement.currentTime;
        if (fakeUpdate) {
          currentTime = fakeUpdateTime + 1;
          fakeUpdateTime = currentTime;
        }
        this.updateGameAudioDisplay(currentTime);
        if (
          this.state.audioDuration &&
          this.state.audioDuration <= currentTime
        ) {
          this.executeEndtimeProcess();
        }
      }
    }, 1000);
  };
  stopCountAndUpdatePlayTime = () => {
    // Clear existing interval if any
    if (this.currentTimeInterval) {
      clearInterval(this.currentTimeInterval);
    }
  };

  removePlayer = async (playerId?: string) => {
    this.setState({
      downloadingGameAudioState: "pristine",
      downloadProgress: 0,
    });
    let playerIdToRemove = playerId;
    if (!playerIdToRemove) {
      playerIdToRemove = localStorage.getItem("userId") ?? "";
    }

    let response;
    if (playerIdToRemove != null && playerIdToRemove?.length) {
      const request = await fetch(
        `${this.props.serverUrl}/remove-player?sessionId=${this.state.sessionId}&playerId=${playerIdToRemove}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      response = await request.json();
    }
    if (
      (response?.playerId === playerIdToRemove ||
        playerIdToRemove == null ||
        response?.playerId == null ||
        !playerIdToRemove?.length) &&
      this.props.userRole !== "host"
    ) {
      this.handlePause();
      this.setState({
        uiState: "onboarding",
        leaveAndResetConfirmation: false,
      });
      this.audioElement = null;
      localStorage.removeItem("userId");
      const databaseName = "gameSessionDB";
      const version = 2;
      const db = await this.openIndexedDB(
        databaseName,
        version,
        "sessions",
        "id"
      );
      const storeName = "sessions";
      const fileName = this.state.sessionId;
      await this.deleteFromIndexedDB(db, storeName, fileName);
    } else {
      this.refreshUserList();
    }
  };

  sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  startEndtimeListener = () => {
    if (this.audioElement) {
      this.audioElement.onended = this.executeEndtimeProcess;
    }
  };
  stopEndtimeListener = () => {
    if (this.audioElement) {
      this.audioElement.onended = null;
    }
  };

  executeEndtimeProcess = () => {
    this.stopCountAndUpdatePlayTime();
    this.stopCheckcurrentTimeInterval();
    this.setState({ audioState: "paused", uiState: "gameEnd" });
    this.socket.emit("endSession", { sessionId: this.state.sessionId });
  };

  ////
  /// DATABASE actions
  ////

  async openIndexedDB(
    dbName: string,
    version: number,
    storeName: string,
    keyPath: string
  ): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, version);
      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const db = (event.target as IDBOpenDBRequest).result;
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, { keyPath: keyPath });
        }
      };
      request.onsuccess = (event) => {
        resolve((event.target as IDBOpenDBRequest).result);
      };
      request.onerror = (event) => {
        reject(event);
      };
    });
  }

  async writeToIndexedDB(
    db: IDBDatabase,
    storeName: string,
    data: any
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([storeName], "readwrite");
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.put(data); // Using 'put' to either add new or update existing data

      request.onsuccess = () => {
        resolve();
      };
      request.onerror = (event) => {
        reject(event);
      };
    });
  }

  async readFromIndexedDB(
    db: IDBDatabase,
    storeName: string,
    key: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([storeName], "readonly");
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.get(key);

      request.onsuccess = () => {
        resolve(request.result);
      };
      request.onerror = (event) => {
        reject(event);
      };
    });
  }

  async deleteFromIndexedDB(
    db: IDBDatabase,
    storeName: string,
    key: any
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([storeName], "readwrite");
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.delete(key);

      request.onsuccess = () => {
        console.log(`Entry with key ${key} deleted successfully.`);
        resolve();
      };
      request.onerror = (event) => {
        console.error("Error deleting entry from IndexedDB:", event);
        reject(event);
      };
    });
  }

  ////
  /// Player Management Functions
  ////

  switchGroup = async (groupId: number) => {
    this.setState({ groupId: groupId });
    // Update the URL query string
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("groupId", groupId.toString());
    window.history.replaceState(null, "", "?" + urlParams.toString());
    this.setState({
      showPlayerManagement: false,
    });
    await this.downloadAudioFiles(groupId, true);
  };

  async refreshUserList() {
    await this.getSessionData();
  }

  ////
  /// Render Functions
  ////
  renderInviteModal = () => {
    let groupName = this.state.currentSessionData?.groupData.find(
      (group) => group.id === this.state.showGroupInviteModal
    )?.name;

    return (
      <InviteModalContainer>
        <GameStateMainContent>
          <TitleBox>
            <Title color="dark" size={2}>
              Invite Players to Group
            </Title>
            <Title color="dark" bold size={3}>
              {groupName}
            </Title>
          </TitleBox>
          {this.state.sessionId &&
            this.state.showGroupInviteModal &&
            this.QRCodeContainerReusable(
              this.state.sessionId,
              null,
              this.props.serverUrl,
              1,
              this.state.showGroupInviteModal
            )}
        </GameStateMainContent>
        <Button
          onClick={() => {
            this.setState({ showGroupInviteModal: null });
          }}
          label={"Go Back"}
          type={"secondary"}
          width={"100%"}
          size="large"
        />
      </InviteModalContainer>
    );
  };

  QRCodeContainerReusable = (
    sessionId: string,
    orientation: "top" | "bottom" | null,
    serverUrl: string,
    scale?: number,
    groupId?: number
  ) => {
    let urlToCopy = `${this.props.serverUrl}/player?sessionId=${sessionId}`;
    if (groupId) {
      urlToCopy += `&groupId=${groupId}`;
    }

    return (
      <QRCodeSection scale={scale} orientation={orientation}>
        <Button
          label={this.state.shareLinkCopied ? "Copied" : "Copy Invite Link"}
          onClick={async () => {
            try {
              await navigator.clipboard.writeText(urlToCopy);
              this.setState({
                shareLinkCopied: true,
              });

              setTimeout(() => {
                this.setState({
                  shareLinkCopied: false,
                });
              }, 2000);
            } catch (error) {
              // Fallback for mobile browsers
              const textArea = document.createElement("textarea");
              textArea.value = urlToCopy;
              textArea.style.position = "fixed"; // Avoid scrolling to bottom
              textArea.style.opacity = "0"; // Hide the textarea
              document.body.appendChild(textArea);
              textArea.focus();
              textArea.select();
              try {
                document.execCommand("copy");
                this.setState({
                  shareLinkCopied: true,
                });

                setTimeout(() => {
                  this.setState({
                    shareLinkCopied: false,
                  });
                }, 2000);
              } catch (err) {
                console.error("Fallback: Oops, unable to copy", err);
              }
              document.body.removeChild(textArea);
            }
          }}
          size="medium"
          type="primary"
          noShadow
          width="fullWidth"
        />

        <QRCodeContainer>
          <QRCode
            size={128}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={urlToCopy}
            viewBox={`0 0 128 128`}
          />
        </QRCodeContainer>
      </QRCodeSection>
    );
  };

  render() {
    const isValidTime =
      !isNaN(this.state.currentGameTimeDisplay) &&
      this.state.currentGameTimeDisplay >= 0;
    const formattedTime = isValidTime
      ? new Date(this.state.currentGameTimeDisplay * 1000)
          .toISOString()
          .substr(11, 8)
      : "00:00";

    const isSmallScreen = window.innerHeight < 600;
    return (
      <MainContainer
        color={
          this.state.uiState === "game"
            ? "blue"
            : this.state.uiState === "gameEnd"
            ? "mintGreen"
            : "magenta"
        }
      >
        {this.state.uiState === "loading" ? (
          <LoadingScreen height={windowHeight} />
        ) : (
          this.props.userRole === "host" && (
            <Container noScroll>
              <HostTopArea>
                You are <strong>Host</strong> playing in group{" "}
                <strong>
                  {
                    this.state.currentSessionData?.groupData.find(
                      (group: GroupData) =>
                        group.id === (this.state.groupId ?? 1)
                    )?.name
                  }
                </strong>
              </HostTopArea>
              <HostMiddleArea
                shouldShrink={isSmallScreen && this.state.isPreviewMode}
              >
                {this.state.sessionId
                  ? this.QRCodeContainerReusable(
                      this.state.sessionId,
                      "top",
                      this.props.serverUrl,
                      1
                    )
                  : null}
              </HostMiddleArea>

              {this.state.isReentering ? (
                <HostBottomArea>
                  <TitleBox margin={"0 0 3vh 0"}>
                    <Title bold color={"colorWhite"} size={2}>
                      Audio Disconnected because of Reload
                    </Title>
                  </TitleBox>
                  <Button
                    label="Re-connect to Game"
                    type="primary"
                    size="medium"
                    width="fullWidth"
                    onClick={() => {
                      this.fakePlayPause();
                      this.setState({
                        audioState: "waitingToPlay",
                        hasInteractedWithAudioPlay: true,
                      });
                      this.jumpToCurrentGameTime(true);
                      this.setState({
                        isReentering: false,
                      });
                    }}
                  />
                </HostBottomArea>
              ) : (
                <HostBottomArea isPreviewMode={this.state.isPreviewMode}>
                  {this.state.isPreviewMode &&
                    (this.state.confirmStartGame ? (
                      <TitleBox margin={"0 0 3vh 0"}>
                        <Title bold size={2}>
                          Are you ready to BAM?
                        </Title>
                        <SubTitle color={"greyScale"}>
                          This will start the game for everyone.
                        </SubTitle>
                      </TitleBox>
                    ) : (
                      <TitleBox margin={"0 0 3vh 0"}>
                        <Title bold size={2}>
                          Preview Mode
                        </Title>
                        <SubTitle color={"greyScale"}>
                          Changes only apply to you until you update to everyone
                        </SubTitle>
                      </TitleBox>
                    ))}
                  {this.state.isPreviewMode && (
                    <ConfirmUpdatesContainer
                      confirmStartGame={this.state.confirmStartGame}
                    >
                      <ConfirmUpdatesButtonContainer>
                        <Button
                          label={
                            this.state.confirmStartGame
                              ? "Start Now"
                              : "Update & Play"
                          }
                          onClick={() => this.confirmPlayTimeChange("play")}
                          type="primary"
                          size="small"
                          width="fullWidth"
                        />
                        {!this.state.confirmStartGame && (
                          <Button
                            label="Update & Pause"
                            onClick={() => this.confirmPlayTimeChange("pause")}
                            type="warning"
                            size="small"
                            width="fullWidth"
                          />
                        )}
                        <Button
                          label="Reset"
                          onClick={this.cancelPlayTimeChange}
                          type="borderOnly"
                          size="small"
                        />
                      </ConfirmUpdatesButtonContainer>
                    </ConfirmUpdatesContainer>
                  )}
                  {!this.state.confirmStartGame && (
                    <PlayTimeContainer>
                      {this.state.downloadingGameAudioState !== "finished" && (
                        <DownloadNoteContainer>
                          {this.state.downloadingGameAudioState === "error" ? (
                            <JoinSessionBox>
                              <Title bold size={2}>
                                {this.state.currentErrorMessage?.includes(
                                  "Network Error"
                                )
                                  ? "Lost Network, Download interrupted"
                                  : "Error Preparing Audio Files"}
                              </Title>
                              <SubTitle>
                                {this.state.currentErrorMessage?.includes(
                                  "Network Error"
                                )
                                  ? "Reload the page to try again"
                                  : this.state.currentErrorMessage}
                              </SubTitle>
                            </JoinSessionBox>
                          ) : (
                            <JoinSessionBox>
                              <TitleBox>
                                <Title bold color={"colorWhite"} size={2}>
                                  Preparing Audio Files...
                                </Title>
                                <Title bold size={2}>
                                  {" "}
                                  {this.state.downloadProgress &&
                                  this.state.downloadProgress > 0
                                    ? this.state.downloadProgress + "%"
                                    : null}{" "}
                                </Title>
                              </TitleBox>
                            </JoinSessionBox>
                          )}
                        </DownloadNoteContainer>
                      )}
                      {this.state.downloadingGameAudioState === "finished" &&
                        !this.state.confirmStartGame &&
                        this.audioElement && (
                          <PlayTimeTextContainer>
                            <PlayTimeContainerSideElement
                              onClick={this.controlRestart}
                            >
                              <Icon name="resetStart" heightAndWidth={"4vh"} />
                            </PlayTimeContainerSideElement>
                            <PlayTimeDisplay
                              isPreviewMode={this.state.isPreviewMode}
                            >
                              {formattedTime}
                            </PlayTimeDisplay>
                            <PlayTimeContainerSideElement />
                          </PlayTimeTextContainer>
                        )}
                    </PlayTimeContainer>
                  )}
                  {this.state.downloadingGameAudioState === "finished" &&
                    !this.state.confirmStartGame && (
                      <TimeRangeSelector>
                        <TimeRangeSlider
                          durationAvailable={this.state.audioDuration != null}
                          type="range"
                          min="0"
                          max={
                            this.audioElement ? this.audioElement.duration : 100
                          }
                          value={this.state.currentGameTimeDisplay}
                          defaultValue={0}
                          onChange={(e) => {
                            if (this.state.audioDuration) {
                              this.handleAudioChange(e.target.valueAsNumber);
                            }
                          }}
                        />
                      </TimeRangeSelector>
                    )}
                  {!this.state.confirmStartGame && (
                    <HostActionContainer>
                      <HostActionItem>
                        <RoundButton
                          type={"glass"}
                          heightAndWidth={"8vh"}
                          icon={"refresh"}
                          onClick={() => {
                            this.fakePlayPause();
                            this.jumpToCurrentGameTime(true);
                          }}
                          label={
                            this.state.isResyncSuccessful
                              ? "Resync Done"
                              : "Resync Time"
                          }
                        />
                      </HostActionItem>
                      <HostActionItem>
                        {this.state.downloadingGameAudioState !== "finished" ? (
                          <LoadingIndicator heightAndWidth="10vh" />
                        ) : (
                          <>
                            {this.state.audioState === "paused" && (
                              <RoundButton
                                label={
                                  this.audioElement?.currentTime === 0
                                    ? "Start Bam"
                                    : "Play"
                                }
                                onClick={() => {
                                  if (
                                    this.state.audioState !== "waitingToPlay"
                                  ) {
                                    this.fakePlayPause();
                                    this.controlPlay();
                                  }
                                  if (this.audioElement?.currentTime === 0) {
                                    this.setState({
                                      confirmStartGame: true,
                                    });
                                  }
                                }}
                                type={"primary"}
                                heightAndWidth={"8vh"}
                                icon={"play"}
                              />
                            )}
                            {this.state.audioState === "waitingToPlay" && (
                              <RoundButton
                                label={"Loading"}
                                type={"primary"}
                                heightAndWidth={"8vh"}
                                icon={<LoadingIndicator heightAndWidth="7vh" />}
                              />
                            )}
                            {this.state.audioState === "playing" && (
                              <RoundButton
                                label={"Pause"}
                                type={"warning"}
                                heightAndWidth={"8vh"}
                                icon={"pause"}
                                onClick={this.controlPause}
                              />
                            )}
                          </>
                        )}
                      </HostActionItem>
                      <HostActionItem>
                        <RoundButton
                          label={
                            <PlayersCountContainer>
                              {this.state.playerCount > 0 && (
                                <PlayersCounter>
                                  {this.state.playerCount}
                                </PlayersCounter>
                              )}{" "}
                              Players
                            </PlayersCountContainer>
                          }
                          type={"glass"}
                          heightAndWidth={"8vh"}
                          icon={"user_add"}
                          onClick={() => {
                            this.setState({
                              showPlayerManagement: true,
                            });
                          }}
                        />
                      </HostActionItem>
                    </HostActionContainer>
                  )}
                </HostBottomArea>
              )}
              {!this.state.confirmStartGame &&
                this.state.showPlayerManagement && (
                  <PlayerManagementHost
                    refreshUserList={async () => {
                      this.refreshUserList();
                    }}
                    endGame={() => {
                      this.executeEndtimeProcess();
                    }}
                    sessionData={this.state.currentSessionData}
                    removePlayer={(userId: string) => this.removePlayer(userId)}
                    serverUrl={this.props.serverUrl}
                    setGroupInviteModal={(groupId: number) => {
                      this.setState({ showGroupInviteModal: groupId });
                    }}
                    goBack={() =>
                      this.setState({
                        showPlayerManagement: false,
                      })
                    }
                    showPlayerList={this.state.showPlayerManagement}
                    switchGroup={this.switchGroup}
                  />
                )}
            </Container>
          )
        )}
        {this.props.userRole === "player" && (
          <Container>
            {this.state.uiState === "onboarding" && this.state.sessionId && (
              <OnboardingFlowPlayer
                serverUrl={this.props.serverUrl}
                sessionId={this.state.sessionId}
                sessionData={
                  this.state.currentSessionData ?? ({} as SessionData)
                }
                joinGame={() => {
                  this.joinGame();
                  this.setState({
                    hasInteractedWithAudioPlay: true,
                  });
                }}
                assetsServerUrl={assetsServerUrl}
                startGameAsPlayer={() => {
                  this.audioElement?.play();
                  this.audioElement?.pause();
                  this.startGameAsPlayer();
                }}
                downloadingGameAudioState={this.state.downloadingGameAudioState}
                currentErrorMessage={this.state.currentErrorMessage}
                downloadProgress={this.state.downloadProgress ?? null}
                fakePlayPause={() => this.fakePlayPause()}
                qrCodeComponent={() => {
                  if (this.state.sessionId) {
                    return this.QRCodeContainerReusable(
                      this.state.sessionId,
                      null,
                      this.props.serverUrl
                    );
                  }
                  return null;
                }}
                removePlayer={() => this.removePlayer()}
              />
            )}
            {this.state.uiState === "game" && (
              <GameViewContainer>
                <PlayerNameContainer>
                  Your Bam Name:{" "}
                  <PlayerName>{this.state.playerName}</PlayerName>
                </PlayerNameContainer>
                <GameStateMainContent>
                  {this.state.sessionId
                    ? this.QRCodeContainerReusable(
                        this.state.sessionId,
                        null,
                        this.props.serverUrl
                      )
                    : null}
                  {this.state.downloadingGameAudioState !== "finished" ? (
                    <JoinSessionBox>
                      <TitleBox>
                        <Title bold color={"colorWhite"} size={2}>
                          Preparing Audio Files...
                        </Title>
                        <Title bold size={2}>
                          {" "}
                          {this.state.downloadProgress &&
                          this.state.downloadProgress > 0
                            ? this.state.downloadProgress + "%"
                            : null}{" "}
                        </Title>
                      </TitleBox>
                    </JoinSessionBox>
                  ) : (
                    <>
                      {this.state.isReentering &&
                        this.state.audioState !== "paused" &&
                        this.state.downloadingGameAudioState === "finished" && (
                          <TitleBox margin={"5vh 0 2vh 0"}>
                            <Title bold size={3}>
                              Join Game Again
                            </Title>
                            <SubTitle>Click the green button below</SubTitle>
                          </TitleBox>
                        )}

                      {this.state.audioState === "paused" &&
                        this.state.downloadingGameAudioState === "finished" && (
                          <TitleBox margin={"5vh 0 2vh 0"}>
                            <Title bold size={3}>
                              Waiting Room
                            </Title>
                            <SubTitle>
                              The host will start the game soon.
                              <br />
                              Invite a few more people with the QR code!{" "}
                            </SubTitle>
                          </TitleBox>
                        )}

                      {this.state.audioState === "playing" &&
                        !this.state.isReentering && (
                          <TitleBox margin={"5vh 0 2vh 0"}>
                            <Title bold size={2}>
                              {this.state.currentGameTimeDisplay === 0
                                ? "--:--"
                                : new Date(
                                    this.state.currentGameTimeDisplay * 1000
                                  )
                                    .toISOString()
                                    .substr(14, 5)}
                              <br />
                              <Title bold size={3}>
                                Game in Progress
                              </Title>
                            </Title>
                            <SubTitle>
                              Put your phone in the pocket
                              <br /> and start grooving!
                            </SubTitle>
                          </TitleBox>
                        )}
                    </>
                  )}
                </GameStateMainContent>
                <HostBottomArea>
                  <HostActionContainer>
                    <HostActionItem>
                      <RoundButton
                        onClick={async () => {
                          if (this.state.leaveAndResetConfirmation) {
                            await this.removePlayer();
                          } else {
                            this.setState({
                              leaveAndResetConfirmation: true,
                            });
                          }
                        }}
                        label={
                          this.state.leaveAndResetConfirmation
                            ? "Confirm Reset"
                            : "Leave Session"
                        }
                        type="glass"
                        heightAndWidth={"8vh"}
                        icon={
                          this.state.leaveAndResetConfirmation
                            ? "thumbsUp"
                            : "exit"
                        }
                      />
                    </HostActionItem>
                    <HostActionItem>
                      {this.state.audioState === "playing" && (
                        <RoundButton
                          onClick={() => {
                            this.audioElement?.play();
                            this.audioElement?.pause();
                            if (
                              this.state.downloadingGameAudioState ===
                              "finished"
                            ) {
                              this.setState({
                                hasInteractedWithAudioPlay: true,
                              });
                              this.jumpToCurrentGameTime(true);
                              this.setState({
                                isReentering: false,
                              });
                            }
                          }}
                          label={
                            this.state.isReentering
                              ? "Rejoin Game"
                              : this.state.isResyncSuccessful
                              ? "Resync Done"
                              : "Resync Time"
                          }
                          type="primary"
                          heightAndWidth={"8vh"}
                          icon={
                            this.state.isResyncSuccessful
                              ? "thumbsUp"
                              : "refresh"
                          }
                        />
                      )}
                    </HostActionItem>
                    <HostActionItem>
                      <RoundButton
                        type="glass"
                        heightAndWidth={"8vh"}
                        icon={"helpIcon"}
                        onClick={() =>
                          this.setState({
                            showTroubleShooting: true,
                          })
                        }
                        label="Need Help?"
                      />
                    </HostActionItem>
                  </HostActionContainer>
                </HostBottomArea>
                <BouncingImages isFullScreen />
              </GameViewContainer>
            )}{" "}
            {this.state.showTroubleShooting && (
              <TroubleShooting
                step={"runningGame"}
                closeComponent={() =>
                  this.setState({
                    showTroubleShooting: false,
                  })
                }
              />
            )}
            {this.state.uiState === "gameEnd" && (
              <ContentContainer>
                <TopBoxGameEnded>
                  <Title size={2} color="dark">
                    Game Over!
                  </Title>
                </TopBoxGameEnded>

                <BottomBoxGameEnded>
                  <ButtonColumn>
                    <Button
                      onClick={() => {
                        window.open("https://bam.baby", "_blank");
                      }}
                      label="Host your own Bam party"
                      type="primary"
                      width="fullWidth"
                      size="medium"
                    />
                    <Button
                      onClick={() => {
                        window.open("https://t.me/+GC77I3kzf3Q3NzUy", "_blank");
                      }}
                      label="Follow updates on Telegram"
                      type="borderOnly"
                      width="fullWidth"
                      size="medium"
                    />
                    {/* <div style={{ width: "45%" }}>
                      <form
                        action="https://www.paypal.com/donate"
                        method="post"
                        target="_blank"
                      >
                        <input
                          type="hidden"
                          name="hosted_button_id"
                          value="U4J4EVRVBHKUU"
                        />
                        <input
                          type="image"
                          src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif"
                          name="submit"
                          title="PayPal - The safer, easier way to pay online!"
                          alt="Donate with PayPal button"
                          style={{ width: "100%" }}
                        />
                      </form>
                    </div> */}
                  </ButtonColumn>
                </BottomBoxGameEnded>
                <Donations />
              </ContentContainer>
            )}
          </Container>
        )}
        {this.state.showGroupInviteModal ? this.renderInviteModal() : null}
      </MainContainer>
    );
  }
}

export default Gamesession;

const QRCodeSection = styled.div<{
  orientation?: "top" | "bottom" | null;
  scale?: number;
}>`
  display: flex;
  flex-direction: column;
  grid-gap: 1vh;
  align-items: center;
  justify-content: center;
  /* padding: 20%; */
  width: 60%;
  min-width: 50vw;
  box-sizing: border-box;
  z-index: 2;

  scale: ${(props) => props.scale};
  ${(props) =>
    props.orientation === "top" &&
    css`
      position: relative;
    `}
  ${(props) =>
    props.orientation === "bottom" &&
    css`
      position: fixed;
    `};

  > div {
    width: 100%;
  }

  @media (max-height: 900px) {
    max-width: 20%;
    scale: 0.7;
    margin-top: -0vh;
    height: 30vh;
  }

  @media (min-height: 901px) {
    max-width: 80%;
  }
`;

const QRCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: white;
  width: fit-content;
  height: fit-content;
  border-radius: 1.5vw;
  z-index: 2;

  box-shadow: 4px 4px 0px rgba(0, 0, 0, 1);
`;

const DownloadNoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PlayTimeDisplay = styled.div<{
  isPreviewMode?: boolean;
}>`
  color: #f1f1f1;
  font-size: 8vw;
  text-align: center;
  font-weight: bold;
  flex: 1;
  min-width: 40vw;
  white-space: nowrap;

  @media (max-width: 1200px) {
    font-size: 8vw;
  }

  @media (min-width: 1201px) {
    font-size: 4vw;
  }

  ${(props) =>
    props.isPreviewMode &&
    css`
      color: ${THEME().colors.greyScale400};
    `}
`;

const JoinSessionBox = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 3vh;
  align-items: center;
  min-height: 15vh;

  position: relative;
`;

const Container = styled.div<{
  noScroll?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: ${windowHeight}px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;

  ${(props) =>
    props.noScroll &&
    css`
      overflow: unset;
    `}
`;

const PlayTimeContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 3vh;
  width: 100%;
`;
const PlayTimeContainerSideElement = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex: 1;
`;
const PlayTimeTextContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const PlayerNameContainer = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 2vw;
  margin-bottom: 1vh;
  font-size: 3vw;
  border-radius: 2vw;
  color: ${dark};
  padding: 2vw;
  flex-direction: row;
  z-index: 2;
  justify-content: center;

  width: 100%;
  background: ${THEME().colors.mintGreen};

  @media (max-width: 1200px) {
    font-size: 4vw;
  }

  @media (min-width: 1201px) {
    font-size: 1.5vw;
  }
`;
const PlayerName = styled.div`
  display: flex;
  align-items: center;
  color: ${dark};
  border-radius: 10vh;
  font-weight: bold;
  @media (max-width: 1200px) {
    font-size: 4vw;
  }

  @media (min-width: 1201px) {
    font-size: 1.5vw;
  }
`;

const InviteModalContainer = styled.div`
  display: flex;
  position: absolute;
  height: ${windowHeight}px;
  grid-gap: 5vh;
  width: 100vw;
  align-items: center;
  justify-content: space-between;
  background: ${THEME().colors.yellow};
  z-index: 100;
  flex-direction: column;
  padding: 4vh;
  box-sizing: border-box;
`;

const DropDownGroupSelection = styled.select`
  width: fit-content;
  height: 5vh;
  font-size: 2vw;
  border-radius: 1vw;
  border: none;
  display: flex;
  grid-gap: 4vw;
  color: #f1f1f1;
  background: transparent;
  @media (max-width: 1200px) {
    font-size: 4vw;
  }

  @media (min-width: 1201px) {
    font-size: 1.5vw;
  }
`;
const DropDownGroupSelectionEntry = styled.option`
  font-size: 2vw;

  @media (max-width: 1200px) {
    font-size: 4vw;
  }

  @media (min-width: 1201px) {
    font-size: 1.5vw;
  }
`;

const ButtonContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0 5vh 5vh 5vh;
  box-sizing: border-box;
`;

const GameStateMainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 5vh;
  width: 100%;
  padding: 0 4vw;
  box-sizing: border-box;
  flex: 1;
`;

const GameViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: ${windowHeight}px;
  width: 100%;
  box-sizing: border-box;
`;

const TopBoxGameEnded = styled.div`
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${THEME().colors.yellow};
  width: 100%;
`;
const BottomBoxGameEnded = styled.div`
  height: 80vh;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 3vh;
  padding: 3vw;
`;

const HostTopArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 6vh;
  color: ${THEME().colors.colorWhite};
  background: ${THEME().colors.darkBlue};

  font-size: 2vw;

  @media (max-width: 1200px) {
    font-size: 4vw;
  }

  @media (min-width: 1201px) {
    font-size: 1.5vw;
  }

  > strong {
    margin: 0 1.5vw;
  }
`;
const HostMiddleArea = styled.div<{
  shouldShrink: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 20vh;
  flex: 1;
  box-sizing: border-box;
  padding: 20%;
  box-sizing: border-box;

  ${(props) =>
    props.shouldShrink &&
    css`
      height: calc(100% - 30%);
    `}
`;

const HostBottomArea = styled.div<{
  isPreviewMode?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  min-height: fit-content;
  flex-direction: column;
  padding: 4vh;
  box-sizing: border-box;

  ${(props) =>
    props.isPreviewMode &&
    css`
      background: white;
      border-radius: 3vw 3vw 0 0;
    `}
`;

const TimeRangeSelector = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const HostActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 3vh;
  min-height: 14vh;
`;

const HostActionItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  flex: 1;
  position: relative;
`;

const TimeRangeSlider = styled.input<{
  durationAvailable?: boolean;
}>`
  width: 100%;
  height: 3vh;
  border-radius: 10vh;

  -webkit-appearance: none; /* Remove default appearance */

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 3vh;
    background: linear-gradient(
      to right,
      ${THEME().colors.mintGreen} var(--value, 0%),
      #ddd var(--value, 0%)
    ); /* Gradient for passed time */
    border-radius: 10vh;

    opacity: ${(props) => (props.durationAvailable ? 1 : 0)};
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none; /* Remove default appearance */
    width: 4vh;
    height: 4vh;
    background: ${THEME().colors.colorWhite};
    border: 1vh solid ${THEME().colors.dark};
    border-radius: 50%;
    cursor: pointer;
    margin-top: -0.5vh; /* Center the thumb */
  }
`;

const ConfirmUpdatesContainer = styled.div<{
  confirmStartGame?: boolean;
}>`
  background: ${THEME().colors.colorWhite};
  display: flex;
  flex-direction: column;
  grid-gap: 2vh;
  box-sizing: border-box;
  width: 110%;
  display: flex;
  border-bottom: 1px solid ${THEME().colors.lightGrey};
  flex-direction: column;
  /* padding: 5vw; */
  /* margin: 0 -5vw; */
  grid-gap: 2vh;
  box-sizing: border-box;
  margin-right: -5%;
  padding-bottom: 3vh;
  margin-bottom: 3vh;

  ${(props) =>
    props.confirmStartGame &&
    css`
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: unset;
    `}
`;
const ConfirmUpdatesButtonContainer = styled.div`
  display: flex;
  width: 100%;
  grid-gap: 2vw;
  align-items: center;
  justify-content: space-between;
`;

const PlayersCountContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1vw;
`;

const PlayersCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8vh 1.3vw;
  border-radius: 10vh;
  background: ${THEME().colors.warning};
  color: ${THEME().colors.dark};

  @media (max-width: 1200px) {
    font-size: 3vw;
  }

  @media (min-width: 1201px) {
    font-size: 1vw;
  }
`;
