import React from "react";
import styled from "styled-components";
import { THEME, IconThemeKeys } from "./theme";

// Styled component for the SVG icon
const StyledIcon = styled.svg<{
  heightAndWidth: string;
}>`
  width: ${(props) => props.heightAndWidth};
  height: ${(props) => props.heightAndWidth};
`;

// Icon component
interface IconProps {
  heightAndWidth: string;
  name: IconThemeKeys;
}

// Icon component
const Icon: React.FC<IconProps> = ({ heightAndWidth, name }) => {
  const iconPath = THEME().icons[name];

  if (!iconPath) {
    console.error(`Icon "${name}" not found in theme.`);
    return null;
  }

  return (
    <StyledIcon
      heightAndWidth={heightAndWidth}
      as="img"
      src={iconPath}
      alt={`${name} icon`}
      style={{ width: heightAndWidth, height: heightAndWidth }}
    />
  );
};

export default Icon;
