import { Component } from "react";
import styled from "styled-components";
import { TroubleShooting } from "./TroubleShooting";
import { Button } from "./Button";
import {
  ButtonColumn,
  ContentContainer,
  SubTitle,
  Title,
  TitleBox,
  blue,
  colorWhite,
} from "./commonUI";
import { LoadingScreen } from "./LoadingScreen";
import { SessionData } from "./database.types";
import BouncingImages from "./bouncingImagesBackground";
import { LoadingIndicator } from "./LoadingIndicator";

interface Props {
  sessionId: string;
  joinGame: () => void;
  serverUrl: string;
  assetsServerUrl: string;
  startGameAsPlayer: () => void;
  downloadingGameAudioState: "pristine" | "running" | "finished" | "error";
  downloadProgress: number | null;
  fakePlayPause: () => void;
  qrCodeComponent: () => JSX.Element | null;
  removePlayer: () => Promise<void>;
  currentErrorMessage: string | null;
  sessionData: SessionData;
}

const windowHeight = window.innerHeight;

interface State {
  currentStep:
    | "welcome"
    | "setupConnection"
    | "soundCheck"
    | "doNotDisturbMode"
    | "downloadAudio"
    | "audioIntroduction"
    | null;
  soundCheckRunning: boolean | null;
  soundCheckFile: Blob | null;
  showTroubleShooting: boolean;
  confirmRemoveUser: boolean;
  loadingScreen: boolean;
}

export class OnboardingFlowPlayer extends Component<Props, State> {
  // Properties to hold the state and configuration of the onboarding process
  onboardingAudioElement: HTMLAudioElement | null = null;

  state: State = {
    currentStep: "welcome",
    soundCheckRunning: null,
    soundCheckFile: null,
    showTroubleShooting: false,
    confirmRemoveUser: false,
    loadingScreen: true,
  };
  async componentDidMount(): Promise<void> {
    this.setState({
      loadingScreen: true,
    });
    await this.loadSoundCheckFile();
    // await this.preloadImages();
    // You can update the state here to indicate that loading is complete
    this.setState({
      loadingScreen: false,
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevProps.downloadingGameAudioState !== "finished" &&
      this.props.downloadingGameAudioState === "finished"
    ) {
      // this.setState({
      //   currentStep: "audioIntroduction",
      // });
    }
    if (
      prevState.currentStep === "soundCheck" &&
      this.state.currentStep !== "soundCheck"
    ) {
      this.controlSoundCheck(false);
    }
  }

  componentWillUnmount() {
    this.controlSoundCheck(false);
    this.onboardingAudioElement = null;
  }

  preloadImages = async (): Promise<void> => {
    const imagesToPreload = [
      `${this.props.assetsServerUrl}/assets/onboarding/jakeHead.png`,
      `${this.props.assetsServerUrl}/assets/onboarding/jakeMusic.png`,
      `${this.props.assetsServerUrl}/assets/onboarding/jakeFinnPlaying.gif`,
      `${this.props.assetsServerUrl}/assets/onboarding/finnMeditation.png`,
      `${this.props.assetsServerUrl}/assets/onboarding/beemo.png`,
      `${this.props.assetsServerUrl}/assets/onboarding/jakeFinnHighFive.png`,
    ];

    const imagePromises = imagesToPreload.map((imageUrl) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    await Promise.all(imagePromises);
  };

  // Soundcheck related functions

  controlSoundCheck = (state: boolean) => {
    this.setState({
      soundCheckRunning: state,
    });

    if (state) {
      if (!this.onboardingAudioElement && this.state.soundCheckFile) {
        this.onboardingAudioElement = new Audio(
          URL.createObjectURL(this.state.soundCheckFile)
        );
      }
      this.onboardingAudioElement?.play();
    } else {
      this.onboardingAudioElement?.pause();
    }
  };

  loadSoundCheckFile = async () => {
    let soundCheckFile: Blob | null = null;
    const response = await fetch(
      `${this.props.assetsServerUrl}assets/audio/preflightSound.m4a`
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.text()}`);
    } else {
      soundCheckFile = await response.blob();
      this.setState({
        soundCheckFile: soundCheckFile,
      });
    }
    this.onboardingAudioElement = new Audio(
      URL.createObjectURL(soundCheckFile)
    );
  };

  ///

  render() {
    if (this.state.loadingScreen) {
      return (
        <OnboardingContainer>
          <ContentContainer>
            <LoadingScreen height={windowHeight} />
          </ContentContainer>
        </OnboardingContainer>
      );
    }

    if (this.state.showTroubleShooting && this.state.currentStep !== null) {
      return (
        <OnboardingContainer>
          <ContentContainer>
            <TroubleShooting
              step={this.state.currentStep}
              closeComponent={() =>
                this.setState({
                  showTroubleShooting: false,
                })
              }
            />
          </ContentContainer>
        </OnboardingContainer>
      );
    }
    // Step 1 - Welcome screen
    if (this.state.currentStep === "welcome") {
      return (
        <OnboardingContainer>
          <TopContainer>
            <Title bold horizontalAlign={"left"} size={2.8}>
              {this.props.sessionData.gameName ?? "Welcome to BAM"}
            </Title>
          </TopContainer>
          <BottomContainer>
            <SubTitle horizontalAlign={"left"}>
              {this.props.sessionData.gameDescription ??
                stepData[this.state.currentStep].description}
            </SubTitle>
            <QRCodeContainer>{this.props.qrCodeComponent()}</QRCodeContainer>
          </BottomContainer>
          <ButtonContainer>
            <ButtonColumn>
              <Button
                onClick={async () => {
                  this.setState({
                    currentStep: "doNotDisturbMode",
                  });
                  this.props.joinGame();
                }}
                label={stepData[this.state.currentStep].nextButtonText}
                type="borderOnly"
                size="large"
                width="fullWidth"
              />
            </ButtonColumn>
          </ButtonContainer>
          <BouncingImages isFullScreen />
        </OnboardingContainer>
      );
    }

    // Step 2 - Enable Do not Disturb mode

    if (this.state.currentStep === "doNotDisturbMode") {
      return (
        <OnboardingContainer>
          <EmptyTopSpace />
          <TitleBox margin={"5vh 0 2vh 0"}>
            <Title bold size={1.8}>
              {stepData[this.state.currentStep].title}
            </Title>
            <SubTitle width="80%">
              {stepData[this.state.currentStep].description}
            </SubTitle>
          </TitleBox>
          <ButtonContainer>
            <ButtonColumn>
              <Button
                onClick={() => {
                  this.setState({
                    currentStep: "setupConnection",
                  });
                }}
                label={stepData[this.state.currentStep].nextButtonText}
                type="borderOnly"
                size="large"
                width="fullWidth"
              />
            </ButtonColumn>
          </ButtonContainer>
          <BouncingImages isFullScreen />
        </OnboardingContainer>
      );
    }

    // Step 3 - Plug in or connect your headphones (& download test audio file in the background)

    if (this.state.currentStep === "setupConnection") {
      return (
        <OnboardingContainer>
          <EmptyTopSpace />
          <TitleBox margin={"5vh 0 2vh 0"}>
            <Title bold size={2}>
              {stepData[this.state.currentStep].title}
            </Title>
            <SubTitle width="100%">
              {stepData[this.state.currentStep].description}
            </SubTitle>
          </TitleBox>

          <ButtonContainer>
            <ButtonColumn>
              <Button
                onClick={() =>
                  this.setState({
                    showTroubleShooting: true,
                  })
                }
                type="warning"
                label="Need Help?"
                size="large"
                width="fullWidth"
              />
              <Button
                onClick={() => {
                  this.setState({
                    currentStep: "soundCheck",
                  });
                  this.controlSoundCheck(true);
                }}
                label={stepData[this.state.currentStep].nextButtonText}
                type="borderOnly"
                size="large"
                width="fullWidth"
              />
            </ButtonColumn>
          </ButtonContainer>
          <BouncingImages isFullScreen />
        </OnboardingContainer>
      );
    }

    // Step 3 - Listen to the audio test

    if (this.state.currentStep === "soundCheck") {
      return (
        <OnboardingContainer>
          <EmptyTopSpace />
          <TitleBox margin={"5vh 0 2vh 0"}>
            <Title bold size={2}>
              {stepData[this.state.currentStep].title}
            </Title>
          </TitleBox>
          <QRCodeContainer>{this.props.qrCodeComponent()}</QRCodeContainer>
          <ButtonContainer>
            <ButtonColumn>
              <Button
                onClick={() =>
                  this.setState({
                    showTroubleShooting: true,
                  })
                }
                type="warning"
                label="Need Help?"
                size="large"
                width="fullWidth"
              />

              {this.state.soundCheckRunning != null && (
                <Button
                  onClick={() => {
                    if (this.props.downloadingGameAudioState === "finished") {
                      this.props.startGameAsPlayer();
                    }
                  }}
                  label={
                    this.props.downloadingGameAudioState === "finished" ? (
                      stepData[this.state.currentStep].nextButtonText
                    ) : (
                      <span>
                        <strong>
                          {this.props.downloadProgress &&
                          this.props.downloadProgress > 0
                            ? this.props.downloadProgress + "%  "
                            : null}
                        </strong>{" "}
                        Waiting for Download
                      </span>
                    )
                  }
                  type="primary"
                  size="large"
                  width="fullWidth"
                />
              )}
            </ButtonColumn>
          </ButtonContainer>
          <BouncingImages isFullScreen />
        </OnboardingContainer>
      );
    }

    // // Step 4 - Listen to the audio test

    // if (this.state.currentStep === "downloadAudio") {
    //   return (
    //     <OnboardingContainer>
    //       <ContentContainer>
    //         <DownloadNoteContainer>
    //           {this.props.downloadingGameAudioState === "error" ? (
    //             <JoinSessionBox>
    //               <Title size={2}>Error Downloading Audio Files</Title>
    //               <SubTitle>{this.props.currentErrorMessage}</SubTitle>
    //             </JoinSessionBox>
    //           ) : (
    //             <JoinSessionBox>
    //               <TitleBox margin={"5vh 0 2vh 0"}>
    //                 <Title size={1.7}>
    //                   {" "}
    //                   {this.props.downloadProgress &&
    //                   this.props.downloadProgress > 0
    //                     ? this.props.downloadProgress + "% - "
    //                     : null}{" "}
    //                   Preparing Audio Files...
    //                 </Title>
    //                 <SubTitle>This can take a second</SubTitle>
    //               </TitleBox>
    //             </JoinSessionBox>
    //           )}
    //         </DownloadNoteContainer>
    //         <ButtonColumn>
    //           <Button
    //             onClick={() =>
    //               this.setState({
    //                 showTroubleShooting: true,
    //               })
    //             }
    //             type="warning"
    //             label="Having Problems?"
    //             size="large"
    //             width="fullWidth"
    //           />
    //           <Button
    //             onClick={async () => {
    //               if (!this.state.confirmRemoveUser) {
    //                 this.setState({
    //                   confirmRemoveUser: true,
    //                 });
    //               } else {
    //                 await this.props.removePlayer();
    //                 this.setState({
    //                   confirmRemoveUser: false,
    //                   currentStep: "welcome",
    //                 });
    //               }
    //             }}
    //             type="warning"
    //             label={
    //               !this.state.confirmRemoveUser
    //                 ? "Reset & Start Over"
    //                 : "Confirm"
    //             }
    //             size="large"
    //             width="fullWidth"
    //           />
    //         </ButtonColumn>
    //         <OnboardingImage
    //           src={`${this.props.assetsServerUrl}/assets/onboarding/beemo.png`}
    //         />
    //       </ContentContainer>
    //     </OnboardingContainer>
    //   );
    // }
    // // Step 4 - success!

    // if (this.state.currentStep === "audioIntroduction") {
    //   return (
    //     <OnboardingContainer>
    //       <ContentContainer>
    //         {/* <TroubleShooting step={this.state.currentStep} /> */}
    //         <TitleBox margin={"5vh 0 2vh 0"}>
    //           <Title size={2}>{stepData[this.state.currentStep].title}</Title>
    //         </TitleBox>
    //         <ButtonColumn>
    //           <Button
    //             label={stepData[this.state.currentStep].nextButtonText}
    //             type="primary"
    //             onClick={() => {
    //               this.props.startGameAsPlayer();
    //             }}
    //             size="large"
    //             width="fullWidth"
    //           />
    //         </ButtonColumn>

    //         <OnboardingImage
    //           src={`${this.props.assetsServerUrl}/assets/onboarding/jakeFinnHighFive.png`}
    //           width="100%"
    //           bottom={"0"}
    //         />
    //       </ContentContainer>
    //     </OnboardingContainer>
    //   );
    // }
  }
}

const stepData = {
  welcome: {
    title: "Welcome to the BAM",
    description: "Let's get you up and dancing in 30 seconds.",
    nextButtonText: "Start Onboarding",
  },
  doNotDisturbMode: {
    title: (
      <span>
        KEEP your network connection <br /> & ENABLE Do Not Disturb Mode
      </span>
    ),
    description:
      "You really don't want to get a call while in the zone, do you?",

    nextButtonText: "Next Step",
  },
  setupConnection: {
    title: "Connect your headphones",
    description: "Both bluetooth and cabled headphones are fine.",
    nextButtonText: "Headphones are connected",
  },
  soundCheck: {
    title: <span>Listen to this short onboarding audio</span>,
    description:
      "Why don't you invite more of your friends to join the game? The more the bammer!",
    nextButtonText: "I am ready!",
  },
  downloadAudio: {
    title: "Listen to the audio test",
    description: "Listen to the audio test and adjust the volume as needed.",
    nextButtonText: "",
  },
  audioIntroduction: {
    title: "Onboarding complete",
    description:
      "Congratulations! You have successfully completed the onboarding process.",
    nextButtonText: "Join Game",
  },
};

const JoinSessionBox = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  align-items: center;
`;

const DownloadNoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const OnboardingImage = styled.img<{ width?: string; bottom?: string }>`
  border-radius: 15px;
  max-width: ${(props) => (props.width ? props.width : "90%")};
  position: fixed;
  bottom: ${(props) => (props.bottom ? props.bottom + "vh" : "1vh")};
  left: auto;
  width: 90%;
  max-height: 50vh;
  object-fit: contain; // This line ensures the aspect ratio is maintained
`;

const ButtonContainer = styled.div`
  position: relative;
  bottom: 5vh;
  width: 100%;
  padding: 0 5vh;
  box-sizing: border-box;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  height: 20vh;
  width: 100%;
  background: ${blue};
  padding: 3vh;
  box-sizing: border-box;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 80vh;
  flex: 1;
  padding: 3vh;
  box-sizing: border-box;
`;

const QRCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  z-index: 1;
`;

const OnboardingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: ${windowHeight}px;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
`;

const EmptyTopSpace = styled.div``;
