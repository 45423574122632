import { Component } from "react";
import styled, { css } from "styled-components";
import { THEME } from "./theme";
import { LoadingIndicator } from "./LoadingIndicator";

interface Props {
  size?: string;
  headLess?: boolean;
  height?: number;
}

interface State {}

export class LoadingScreen extends Component<Props, State> {
  render() {
    return (
      <LoadingBox height={this.props.height} headLess={this.props.headLess}>
        <LoadingIndicator heightAndWidth="10vh" />
      </LoadingBox>
    );
  }
}

const LoadingBox = styled.div<{ headLess?: boolean; height?: number }>`
  height: ${(props) => props.height}px;
  width: 100%;
  background: ${THEME().colors.blue};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  ${(props) =>
    props.headLess &&
    css`
      background: transparent;
    `}
`;
const LoadingSpinner = styled.img<{
  width?: string;
  bottom?: number;
  size?: string;
}>`
  width: ${(props) => (props.size ? props.size : "20vw")};

  height: auto;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 1200px) {
    width: 20vw;
  }

  @media (min-width: 1201px) {
    width: 5vw;
  }
`;
