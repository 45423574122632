import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { Button } from "../Button";
import {
  ContentContainer,
  SubTitle,
  TextField,
  Title,
  TitleBox,
} from "../commonUI";
import { THEME } from "../theme";

const Donations: React.FC = () => {
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();

  return (
    <MainContainer>
      <ContentContainer>
        <JoinSessionBox>
          <TitleBox gridGap={"1vw"}>
            <Title bold size={2.3} color="dark">
              Was this worth more than what you regularly spend money on?
            </Title>
            <SubTitle size={2} color="colorWhite">
              Select an option
            </SubTitle>
          </TitleBox>
          <DonationOptionsContainer>
            <Button
              onClick={() => {
                window.open(
                  "https://donate.stripe.com/3csg2bcR6fSF7kYaEH",
                  "_blank"
                );
              }}
              size="medium"
              type="secondary"
              label={
                <DonationOptionsElement>
                  <Title size={3} color="dark">
                    €5
                  </Title>
                  <SubTitle size={2} color="dark">
                    Cup of Coffee
                  </SubTitle>
                </DonationOptionsElement>
              }
            />
            <Button
              onClick={() => {
                window.open(
                  "https://donate.stripe.com/3cs8zJcR649XdJm004",
                  "_blank"
                );
              }}
              size="medium"
              type="secondary"
              label={
                <DonationOptionsElement>
                  <Title size={3} color="dark">
                    €10
                  </Title>
                  <SubTitle size={2} color="dark">
                    A Döner Kebab (vegan)
                  </SubTitle>
                </DonationOptionsElement>
              }
            />
            <Button
              onClick={() => {
                window.open(
                  "https://donate.stripe.com/6oE7vF5oE9uhbBe6ot",
                  "_blank"
                );
              }}
              size="medium"
              type="secondary"
              label={
                <DonationOptionsElement>
                  <Title size={3} color="dark">
                    €15
                  </Title>
                  <SubTitle size={2} color="dark">
                    A cinema ticket
                  </SubTitle>
                </DonationOptionsElement>
              }
            />
            <Button
              size="medium"
              type="secondary"
              onClick={() => {
                window.open(
                  "https://donate.stripe.com/7sI2bl2cs8qd20EcMS",
                  "_blank"
                );
              }}
              label={
                <DonationOptionsElement>
                  <Title size={3} color="dark">
                    €20
                  </Title>
                  <SubTitle size={2} color="dark">
                    A proper meal
                  </SubTitle>
                </DonationOptionsElement>
              }
            />
          </DonationOptionsContainer>
        </JoinSessionBox>
      </ContentContainer>
    </MainContainer>
  );
};

export default Donations;

const JoinSessionBox = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  align-items: center;
  padding: 30px;
  box-sizing: border-box;
  grid-gap: 30px;
  width: 100%;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
`;

const DonationOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 3vh;
  padding: 3vw;
  width: 100%;
`;

const DonationOptionsElement = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Work Sans", sans-serif;
  width: 100%;

  > * {
    white-space: pre-wrap;
  }
`;
