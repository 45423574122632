import { supaBaseURL } from "./constants";

export const THEME = (): CoreTheme => {
  return {
    colors: {
      magenta: "#FD9FDD",
      dark: "#000000",
      colorWhite: "#fff",
      mintGreen: "#00D68F",
      blue: "#0095FF",
      darkBlue: "#006FD6",
      yellow: "#FFF172",
      warning: "#FFAA00",
      error: "#FF3D71",
      greyScale: "#2E3A59",
      greyScale400: "#8F9BB3",
      lightGrey: "#EFEFEF",
    },
    icons: {
      arrow_left: `${imageAssetSrc}/arrow_left.svg`,
      arrow_swap_horizontal: `${imageAssetSrc}/arrow_swap_horizontal.svg`,
      play: `${imageAssetSrc}/play.svg`,
      rewind: `${imageAssetSrc}/rewind.svg`,
      user_add: `${imageAssetSrc}/user_add.svg`,
      pause: `${imageAssetSrc}/pause.svg`,
      resetStart: `${imageAssetSrc}/resetStart.svg`,
      helpIcon: `${imageAssetSrc}/helpIcon.svg`,
      refresh: `${imageAssetSrc}/refresh.svg`,
      exit: `${imageAssetSrc}/exit.svg`,
      thumbsUp: `${imageAssetSrc}/thumbsUp.svg`,
    },
  };
};

export type ColorThemeKeys =
  | "magenta"
  | "dark"
  | "colorWhite"
  | "mintGreen"
  | "blue"
  | "darkBlue"
  | "yellow"
  | "warning"
  | "error"
  | "greyScale"
  | "greyScale400"
  | "lightGrey";

export type IconThemeKeys =
  | "arrow_left"
  | "arrow_swap_horizontal"
  | "play"
  | "rewind"
  | "user_add"
  | "pause"
  | "resetStart"
  | "helpIcon"
  | "exit"
  | "thumbsUp"
  | "refresh";

const imageAssetSrc = `${supaBaseURL}/storage/v1/object/public/assets/icons`;

export type ThemeColors = keyof ReturnType<typeof THEME>["colors"];

export interface CoreTheme {
  // NOTE: This is intentionally written in singular, not plural, because it's used by the `styled-components-spacing` library.
  // Renaming it breaks spacing through that library.
  colors: { [Key in ColorThemeKeys]: string | any };
  icons: { [Key in IconThemeKeys]: string | any };
}
