import { Component } from "react";
import styled, { css } from "styled-components";
import { THEME } from "./theme";
import { supaBaseURL } from "./constants";

interface Props {
  heightAndWidth?: string;
}

interface State {}

export class LoadingIndicator extends Component<Props, State> {
  render() {
    return (
      <LoadingSpinner
        src={`${supaBaseURL}/storage/v1/object/public/assets/icons/starImage.svg`}
        heightAndWidth={this.props.heightAndWidth}
      />
    );
  }
}

const LoadingSpinner = styled.img<{
  heightAndWidth?: string;
  bottom?: number;
}>`
  height: ${(props) => (props.heightAndWidth ? props.heightAndWidth : "20vw")};
  width: ${(props) => (props.heightAndWidth ? props.heightAndWidth : "20vw")};
  animation: spin 4s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* @media (max-width: 1200px) {
    width: 20vw;
  }

  @media (min-width: 1201px) {
    width: 5vw;
  } */
`;
